import React, { useState, useEffect } from 'react'
import fire, {db} from '../firebase'
import { Redirect } from 'react-router-dom'
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { IconButton } from '@material-ui/core'
import './Signup.css'

function SignupTalent() {

    const [user , setUser] = useState('');
    const [name, setName] = useState('')
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [emailError, setEmailError]= useState('');
    const [passwordError, setPasswordError] = useState('');
    const [passwordType, setPasswordType] = useState('password');
    const [passwordConfirmType, setPasswordConfirmType] = useState('password');
    const [iconPassword, setIconPassword]= useState(<VisibilityIcon/>);
    const [iconPasswordConfirm, setIconPasswordConfirm]= useState(<VisibilityIcon/>);
    

    const togglePassword = () => {
        if(passwordType === 'password'){
            setPasswordType('text')
            setIconPassword(<VisibilityOffIcon/>)
        }
        else if (passwordType === 'text'){
            setPasswordType('password')
            setIconPassword(<VisibilityIcon/>)
        }
    }

    const toggleConfirmPassword = () => {
        if(passwordConfirmType === 'password'){
            setPasswordConfirmType('text')
            setIconPasswordConfirm(<VisibilityOffIcon/>)
        }
        else if (passwordConfirmType === 'text'){
            setPasswordConfirmType('password')
            setIconPasswordConfirm(<VisibilityIcon/>)
        }
    }

    const clearInputs = () => {
        setEmail('')
        setPassword('')
    }

    const clearErrors = () => {
        setEmailError('')
        setPasswordError('')
    }

    const handleSignup = () => {
        clearErrors();
        fire.auth().createUserWithEmailAndPassword(email, password)
        .then(cred=>{
            db.collection('users').add({
                id : cred.user.uid,
                name : name,
                email : email,
                type : 'talent',
                experiences : [],
                formations : [],
                src : 'https://firebasestorage.googleapis.com/v0/b/onatray-3e95a.appspot.com/o/a14d4c46f762607b1c65bf57a6c1e711.png?alt=media&token=0303e79c-9a01-4de3-8180-e6b72fd6ec16'
            })
        })
        .catch(err=>{
            switch(err.code){
                case "auth/email-already-in-use":
                case 'auth/invalid-email':
                    setEmailError("L'email est invalide ou déjà utilisé")
                    break;
                case "auth/weak-password":
                    setPasswordError('Le mot de passe doit faire 6 caractères')
                    break;
            }
        })
        
    }
    useEffect(() => {
        fire.auth().onAuthStateChanged(user=>{
            if(user){
                clearInputs();
                setUser(user);
            }else{
                setUser('')
            }
        })
    }, [])


    return (
        <>{user ? (
        <Redirect to='/talent'/>
        ):(       
            <div className='signup_container'>
                    <div className='signup'>
                        <span style={{paddingTop:'20px'}} className='signup_title'>Creer un compte talent</span>
                        <input required className='signup_input' placeholder='Prénom et Nom' value={name} onChange={(e)=>setName(e.target.value)}></input>
                        <div style={{paddingTop:'20px'}}>
                            <input required className='signup_input' placeholder='Email' value={email} onChange={(e)=>setEmail(e.target.value)}></input>
                        </div>
                        <span className='signup_error'>{emailError}</span>
                    <div className='signup_passwordInputContainer'>
                        <input required type={passwordType} className='signup_input' placeholder='Mot de passe' value={password} onChange={(e)=>setPassword(e.target.value)}>
                        </input>
                        <IconButton className='signup_inputIcon' onClick={togglePassword}>
                            {iconPassword}
                        </IconButton>
                    </div>
                    <div className='signup_passwordInputContainer'>
                        <input required type={passwordConfirmType} className='signup_input' placeholder='Confirmez votre mot de passe' value={passwordConfirm} onChange={(e)=>setPasswordConfirm(e.target.value)}>
                        </input>
                        <IconButton className='signup_inputIcon' onClick={toggleConfirmPassword}>
                            {iconPasswordConfirm}
                        </IconButton>
                    </div>
                        <span className='signup_error'>{passwordError}</span>
                        <button className='signup_button' onClick={handleSignup}>SignUp</button>
                    </div>     
            </div>
        )}
        </>
    )
}

export default SignupTalent;
