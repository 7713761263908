import React, { useState, useEffect } from 'react'
import { db } from '../firebase'
import { connect } from 'react-redux'

function SearchTalentByAnnonce(props) {
    const docId = props.userToDisplay.docId
    const [annonces, setAnnonces] = useState([]) 
    const [annonceChoosen, setAnnonceChoosen] = useState()

    useEffect(()=>{
        
        if(docId){
            db.collection('annonces').where('id', '==', props.userToDisplay.id)
            .onSnapshot((snapshot)=>(
            setAnnonces(snapshot.docs.map(doc=>doc.data()))
            ))
        }

    },[])

    const handleSearch = (i) => {
        //console.log(annonceChoosen)
        console.log(annonces[i])
    }

    return (
        <div className='searchTalent'>
            <div className='search_filter'>
                <select className='search_filterInput' defaultValue='Choisir une annonce' onChange={(e)=>handleSearch(e.target.value)}>
                    <option value='Choisir une annonce'>Choisir une annonce</option>
                    {annonces.map((annonce,i)=>{
                        return <option key={i} value={i}>Annonce n° {i + 1}</option>
                    })}
                </select>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return { userToDisplay : state.user }
}

export default connect(
    mapStateToProps, 
    null
)(SearchTalentByAnnonce);

