import React, { useState, useEffect } from 'react'
import SearchTalent from './SearchTalent'
import SearchTalentByAnnonce  from './SearchTalentByAnnonce'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import fire , { db } from '../firebase'
import './SearchTalent.css'

function SearchTalentContainer(props) {

    const [disconnect, setDisconnect] = useState(false)
    const [isGlobalSearchOpen, setIsGlobalSearchOpen] = useState(true)
    const [isAnnonceSearchOpen, setIsAnnonceSearchOpen] = useState(false)
    const [user, setUser] = useState()

    useEffect(() => {
        fire.auth().onAuthStateChanged(user=>{
            if(user){ 
                db.collection('users').where("id", "==", user.uid)
                .onSnapshot(snapshot=>{
                    props.onSendUser({id: user.uid, type : 'restaurant', docId : snapshot.docs[0].id})    
                })
            }
            else{
                setDisconnect(true)
                props.onSendUser(null)
            }
        })
    }, [])
    

    const openGlobalSearch = () => {
        if(isAnnonceSearchOpen){
            setIsAnnonceSearchOpen(false)
        }
        setIsGlobalSearchOpen(true)
    }

    const openAnnonceSearch = () => {
        if(isGlobalSearchOpen){
            setIsGlobalSearchOpen(false)
        }
        setIsAnnonceSearchOpen(true)
    }

    let childrenToDisplay = <SearchTalent/>

    if(isGlobalSearchOpen){
        childrenToDisplay = <SearchTalent user={user}/>
    }else if (isAnnonceSearchOpen){
        childrenToDisplay = <SearchTalentByAnnonce/>
    }

    if(disconnect){
        return <Redirect to='/home'/>
    }
    else {    
        return (
            <div className='searchTalentContainer'>
                <div className='searchTalentContainer_buttons'>
                    <div className={`search_item ${isGlobalSearchOpen && 'search_itemActive'}`}>
                        <p onClick={openGlobalSearch}>Recherche Globale</p>
                    </div>
                    <div className={`search_item ${isAnnonceSearchOpen && 'search_itemActive'}`}>
                        <p onClick={openAnnonceSearch}>Recherche par annonce</p>
                    </div>
                    {/* <button onClick={openGlobalSearch} className={`search_button ${isGlobalSearchOpen && 'search_buttonOpen'}`}>Recherche globale</button>
                    <button onClick={openAnnonceSearch} className={`search_button ${isAnnonceSearchOpen && 'search_buttonOpen'}`}>Recherche par annonce</button> */}
                </div>
                {childrenToDisplay}
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onSendUser: function(user) { 
            dispatch( {type: 'addUser', user} ) 
        }
    }
}

export default connect(
    null, 
    mapDispatchToProps
)(SearchTalentContainer);
