import React, { useState, useEffect } from 'react'
import { db, storage } from '../firebase'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { toDate } from 'date-fns'

function PersonnalInfo({userToDisplay}) {
    
    const [file, setFile] = useState(null)
    const [error, setError] = useState('')
    const types = ['image/png', 'image/jpeg']
    const [progress, setProgress] = useState();

    const [profilPicture, setProfilPicture] = useState()
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [jobChoosen, setJobChoosen] = useState('')
    const [contractChoosen, setContractChoosen] = useState('')
    const [startDateChoosen, setStartDateChoosen] = useState('')
    const [hourlyAvailabilityChoosen, setHourlyAvailabilityChoosen] = useState('')
    const [birthdate, setBirthdate] = useState()

    const docId = userToDisplay?.docId

    const uploadImages = (e) => {
        let selected = e.target.files[0]
        console.log(selected.type)
        if(types.includes(selected.type)){
            setFile(selected)
            setError('')
        }
        else {
            console.log('je pass la')
            setFile(null)
            setError('Veuillez selectionnez une image en png ou jpg')
        }
    }
    useEffect(() => {
        if(file){
            const storageRef = storage.ref(file?.name);
            const DocRef = db.collection('users').doc(docId);
            
            storageRef.put(file).on('state_changed', (snap) => {
              let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
              setProgress(percentage);
            }, (err) => {
              setError(err);
            }, async () => {
              const url = await storageRef.getDownloadURL();
              await DocRef.update({ src : url });
            });
        } 
    }, [file])
    
    useEffect(() => {
        let docRef = db.collection('users').doc(docId)
        
        docRef.get().then(function(doc) {
            if (doc.exists) {
                setFullName(doc.data().name)
                setEmail(doc.data().email)
                setProfilPicture({src : doc.data().src})
                setBirthdate(doc.data().birthdate)
                setJobChoosen(doc.data().desiredProfession)
                setContractChoosen(doc.data().desiredContract)
                setHourlyAvailabilityChoosen(doc.data().hourlyAvailability)
                setStartDateChoosen(doc.data().availability)
            } else {
               // console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    }, [])

    

    const handleSubmit = () => {
        console.log(jobChoosen, contractChoosen, hourlyAvailabilityChoosen, startDateChoosen, fullName, email)
        console.log(typeof birthdate)
        console.log(new Date (birthdate))
        let docRef = db.collection('users').doc(docId)
        if(docRef){
            docRef.update({
                name : fullName,
                email : email,
                desiredProfession : jobChoosen,
                desiredContract : contractChoosen,
                availability : startDateChoosen,
                hourlyAvailability : hourlyAvailabilityChoosen,
                birthdate : new Date(birthdate)
            })
        }
    }

    let outputToDisplay = <></>
    if(error || progress){
        outputToDisplay = 
        <div className='output'>
            {error && <div className='error'>{error}</div>}
            {progress && <div className='progress'>Chargement : {progress} %
        </div>}
    </div>
    }

    return (
        <div className='personnalInfo'>
            <div className='personnalInfo_container'>
                <div style={{paddingTop:'20px'}}>
                    <img className='personnalInfo_personalPicture' src={profilPicture?.src}/>
                </div>
                <div style={{paddingTop:'20px'}}>
                    <input value={fullName} onChange={(e)=>setFullName(e.target.value)} className='personnalInfo_input' placeholder='Prenom et Nom'></input>
                </div>
                <div style={{paddingTop:'20px'}}>
                    <input value={email} onChange={(e)=>setEmail(e.target.value)} className='personnalInfo_input' placeholder='Prenom et Nom'></input>
                </div>
                <div style={{paddingTop:'20px', display:'flex', flexDirection:'column'}}>
                    <span style={{paddingLeft:'5px'}}>Date de naissance</span>
                    <input onChange={(e)=>setBirthdate(e.target.value)} className='personnalInfo_input' type='date'></input>
                </div>
                <div style={{paddingTop:'20px'}}>
                    <select value={jobChoosen} onChange={(e)=>setJobChoosen(e.target.value)} defaultValue='Poste recherché'  placeholder='Poste recherché' className='personnalInfo_select'>
                        <option value='Poste recherché' disabled>Poste recherché</option>
                        <optgroup  label='En cuisine'>
                            <option value='Chef'>Chef</option>
                            <option value='Chef de partie'>Chef de partie</option>
                            <option value='Commis'>Commis</option>
                            <option value='Plongeur'>Plongeur</option>
                        </optgroup>
                        <optgroup label='En salle'>
                            <option value='Directeur'>Directeur</option>
                            <option value='Manager'>Manager</option>
                            <option value='Chef de rang'>Chef de rang</option>
                            <option value='Runner'>Runner</option>
                            <option value='Barman'>Barman</option>
                        </optgroup>
                    </select>
                </div>
                <div style={{paddingTop:'20px'}}>
                    <select onChange={(e)=>setContractChoosen(e.target.value)} defaultValue='Type de contrat' className='personnalInfo_select'>
                        <option value='Type de contrat' disabled>Type de contrat</option>
                        <option value='CDI'>CDI</option>
                        <option value='CDD'>CDD</option>
                        <option value='Extra'>Extra</option>
                        <option value='Saisonnier'>Saisonnier</option>
                    </select>
                </div>
                <div style={{paddingTop:'20px'}}>
                    <select onChange={(e)=>setHourlyAvailabilityChoosen(e.target.value)} defaultValue='Horaire' className='personnalInfo_select'>
                        <option value='Horaire' disabled>Horaire</option>
                        <option value='Service du midi'>Service du midi</option>
                        <option value='Service du soi'>Service du soir</option>
                        <option value='Midi et après-midi'> Midi et après-midi</option>
                        <option value='Après-midi et soir'>Après-midi et soir</option>
                        <option value='Flexible'>Flexible</option>
                    </select>
                </div>
                <div style={{paddingTop:'20px', display:'flex', flexDirection:'column'}}>
                    <span style={{paddingLeft:'5px'}}>À partir de quand ?</span>
                    <input onChange={(e)=>setStartDateChoosen(e.target.value)} className='personnalInfo_input' type='date'></input>
                </div>
                <div  style={{paddingTop:'20px'}}>
                    <input className='addpic_button' type="file" onChange={uploadImages}/>
                    {outputToDisplay}
                </div>
                {/* <Link to='/talent/monprofil'> */}
                    <button onClick={handleSubmit} className='personnalInfo_button'>Valider</button>
                {/* </Link> */}
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return { userToDisplay : state.user }
}

export default connect(
    mapStateToProps, 
    null
)(PersonnalInfo);