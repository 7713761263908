import React from 'react'
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom'

import './App.css';
import Navbar from './Navbar/RealNavbar'

import RestaurantWishlist from './RestaurantWishlist/RestaurantWishlist'
import RestaurantAnnonce from './RestaurantAnnonce/RestaurantAnnonce'
import RestaurantProfil from './RestaurantProfil/RestaurantProfil'
import RestaurantProfilView from './RestaurantProfil/RestaurantProfilView'
import ModifyRestaurantProfil from './RestaurantProfil/ModifyRestaurantProfil';
import Questionnaire from './RestaurantProfil/Questionnaire'

import TalentProfil from './TalentProfil/TalentProfil'
import TalentProfilView from './TalentProfil/TalentProfilView'
import TalentWishlist from './TalentWishlist/TalentWishlist'
import SearchTalentContainer from './SearchTalent/SearchTalentContainer'
import SearchRestaurantContainer from './SearchRestaurant/SearchRestaurantContainer'
import ModifyTalentProfil from './TalentProfil/ModifyTalentProfil'


import SignupRestau from './Connection/SignupRestau'
import SignupTalent from './Connection/SignupTalent'
import Login from './Connection/Login'
import Logout from './Connection/Logout'
import Home from './Home/Home'
import ContactUs from './Home/ContactUs'

import Apply from './Apply/Apply'

import CropPicture from './CropPicture/CropPicture';
import CropOutput from './CropPicture/CropOutput'

import user from './reducers/user'
import firstTime from './reducers/firstTime'
import {Provider} from 'react-redux';
import {createStore, combineReducers}  from 'redux';


const store = createStore(combineReducers({user, firstTime}));

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Navbar/>
        <Switch>
          <Route path='/home' component={Home}/>
          <Route path='/signup/talent' component ={SignupTalent}/>
          <Route path='/signup/restaurant' component ={SignupRestau}/>
          <Route path='/login' component={Login}/>
          <Route path='/logout' component={Logout}/>
          <Route path='/restaurant' exact component={SearchTalentContainer}/>
          <Route path='/restaurant/profil/:profilId' component={TalentProfilView}/>
          <Route path='/favoris' component={RestaurantWishlist}/>
          <Route path='/annonces' component={RestaurantAnnonce}/>
          <Route path='/restaurant/monprofil' exact component={RestaurantProfil}/>
          <Route path='/modifier/monprofil' component={ModifyRestaurantProfil}/>
          <Route path='/talent' exact component={SearchRestaurantContainer}/>
          <Route path='/restaurant/questionnaire' component={Questionnaire}/>
          <Route path='/talent/monprofil' component={TalentProfil}/>
          <Route path='/talent/favoris' component={TalentWishlist}/>
          <Route path='/talent/profil/:profilId' component={RestaurantProfilView}/>
          <Route path='/talent/modifiermonprofil' component={ModifyTalentProfil}/>
          <Route path='/crop'exact component={CropPicture}/>
          <Route path='/crop/output' component={CropOutput}/>
          <Route path='/contact' component={ContactUs}/>
          <Route path='/postuler/:restaurantId' component={Apply} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;