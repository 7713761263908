import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

function TalentWishlist({userToDisplay}) {

    if(!userToDisplay){
        return <Redirect to='/talent'/>
    }
    return (
        <div>
            <h1>hello from talent fav</h1>
        </div>
    )
}

function mapStateToProps(state) {
    return { userToDisplay : state.user }
}

export default connect(
    mapStateToProps, 
    null
)(TalentWishlist);
