import React, { useState, useEffect } from 'react'
import './RestaurantProfil.css'

import { IconButton } from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import LaunchIcon from '@material-ui/icons/Launch';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { db, storage } from '../firebase'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom';



function ModifyRestaurantProfil({userToDisplay}) {

    
    const [file, setFile] = useState(null)
    const [error, setError] = useState('')
    const [progress, setProgress] = useState();
    const [images, setImages] = useState([]);
    const types = ['image/png', 'image/jpeg']
  
    const [restaurantName, setRestaurantName] = useState('')
    const [adress, setAdress] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [websiteLink, setWebsiteLink] = useState('')
    const [instagramLink, setInstagramLink] = useState('')
    const [facebookLink, setFacebookLink] = useState('')
    const [description, setDescription] = useState('')


    const docId = userToDisplay?.docId

    const uploadImages = (e) => {
        let selected = e.target.files[0]
        console.log(selected.type)
        if(types.includes(selected.type)){
            setFile(selected)
            setError('')
        }
        else {
            console.log('je pass la')
            setFile(null)
            setError('Veuillez selectionnez une image en png ou jpg')
        }
    }



    useEffect(() => {
        if(file){
            const storageRef = storage.ref(file?.name);
            const collectionRef = db.collection('users').doc(docId).collection('images');
            
            storageRef.put(file).on('state_changed', (snap) => {
              let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
              setProgress(percentage);
            }, (err) => {
              setError(err);
            }, async () => {
              const url = await storageRef.getDownloadURL();
              await collectionRef.add({ url });
            });
        }
      }, [file]);
    


    useEffect(() => {
        var docRef = db.collection("users").doc(docId);
        var imageRef = db.collection('users').doc(docId).collection('images')
        docRef.get().then(function(doc) {
            if (doc.exists) {
                setRestaurantName(doc.data().name)
                setDescription(doc.data().description)
                setWebsiteLink(doc.data().websiteLink)
                setFacebookLink(doc.data().facebookLink)
                setInstagramLink(doc.data().instagramLink)
                setAdress(doc.data().adress)
                setPhoneNumber(doc.data().phoneNumber)
            } else {
               // console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });

        imageRef.onSnapshot((snapshot)=>(
            setImages(snapshot.docs.map(doc=>({
                data : doc.data(),
                id : doc.id
            })))   
        ))
       
    }, [])
    
    const submitChanges = () => {
    
        db.collection('users').doc(docId)
        .update({
            adress : adress,
            phoneNumber : phoneNumber,
            websiteLink : websiteLink,
            instagramLink : instagramLink,
            facebookLink: facebookLink,
            description : description,
        })
        
        console.log(adress, phoneNumber, websiteLink, instagramLink, facebookLink, description)
        return <Redirect to='/restaurant/monprofil'/>
    }
    const deleteImage = (url, id) => {
        console.log(url)
        console.log(id)
       let imageRef =  db.collection('users').doc(docId).collection('images').doc(id);
       if(url && id){
           imageRef.delete();
           storage.refFromURL(url).delete();
       }
    }

    if(!userToDisplay){
        return <Redirect to='/restaurant'/>
    }else {
        return (
            <div className='profil'>
                <header className='profilCover'  style={{
                        backgroundSize:'cover',
                        // backgroundImage:`url(${restaurant.coverPicureLink}    
                        // )`,
                        backgroundColor:'lightgray',
                        backgroundPosition : 'center center'
                    }}>
                    <div className='profilCover_contents'>
                        <div className='profilCover_description'> 
                            <div className='profilCover_titleContainer'>
                                <span className='profilCover_title'>
                                   { restaurantName}
                                </span>
                            </div>
                            <div className='profilInfo_items'> 
                                <IconButton>
                                    <LocationOnIcon/> 
                                </IconButton> 
                                <input onChange={(e)=>setAdress(e.target.value)} value={adress} className='modifyProfil_input' placeholder='Adresse'></input>
                            </div>
                            <div className='profilInfo_items'> 
                                <IconButton>
                                    <PhoneIcon/>
                                </IconButton>
                                <input onChange={(e)=>setPhoneNumber(e.target.value)} value={phoneNumber} className='modifyProfil_input' placeholder='Numero de telephone'></input>
                            </div>
                            <div className='profilInfo_items'>
                                <IconButton>
                                    <LaunchIcon/>
                                </IconButton>
                                <input onChange={(e)=>setWebsiteLink(e.target.value)} value={websiteLink} className='modifyProfil_input' placeholder='Lien site internet'></input>
                            </div>
                            <div className='profilInfo_items'>
                                <IconButton>
                                    <InstagramIcon/>
                                </IconButton>
                                <input onChange={(e)=>setInstagramLink(e.target.value)} value={instagramLink} className='modifyProfil_input' placeholder='Lien Instagram'></input>
                            </div>
                            <div className='profilInfo_items'>
                                <IconButton>
                                    <FacebookIcon/>
                                </IconButton>
                                <input onChange={(e)=>setFacebookLink(e.target.value)} value={facebookLink} className='modifyProfil_input' placeholder='Lien Facebook'></input>
                            </div>
                        </div>
                    </div>
                    <div className='profilCover_fadebottom'/>
                </header>
                <div className='profilContent'>
                    <Link to='/restaurant/questionnaire'  className='profilContent_link'>
                        <button className='button'>Répondre au questionnaire</button>
                    </Link>
                    <h2 className='profilContent_title'>Découvrez Perruche</h2>
                    <textarea placeholder='Ajoutez une description' value={description} onChange={(e)=>setDescription(e.target.value)} className='profilContent_description txt_area'></textarea>
                    <h2 className='profilContent_title'>Galerie photo</h2>
                    <div className='profilRow'>
                        <div className='profilRow_posters'>
                            {images.map((image, i) => (
                                <>
                                <img 
                                key={i}
                                className='profilRow_poster'
                                src={image.data.url} 
                                alt={i}/>
                                <IconButton onClick={()=>deleteImage(image.data.url, image.id)}><DeleteForeverIcon/></IconButton>
                                </>
                            ))}
                        </div>
                    </div>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <h2 className='profilContent_title'>Ajouter des photos</h2>
                        <input className='addpic_button' type="file" onChange={uploadImages}/>
                        <div className='output'>
                            {error && <div className='error'>{error}</div>}
                            {progress && <div className='progress'>Chargement : {progress} %</div>}
                        </div>
                    </div>
                        {/* <Grid
                            container
                            spacing={3}
                            style={{ display: "flex", justifyContent: "center" }}
                        >
                            {images.map((image,i)=>{
                              return <div key={i} className='imageContainer'><img className='image'  alt='image' docImageId={image.id} src={image.data.url}/><IconButton onClick={()=>deleteImage(image.data.url, image.id)}><DeleteForeverIcon/></IconButton></div>
                            })}
                        </Grid> */}
                    
                </div>
                <div className='modifyProfil_footer'>
                    <Link className='profilContent_link' to='/restaurant/monprofil'>
                        <button onClick={submitChanges} className='button'>Valider</button>
                    </Link>
                </div>
            </div>
        )
    }   
}

function mapStateToProps(state) {
    return { userToDisplay : state.user }
}

export default connect(
    mapStateToProps, 
    null
)(ModifyRestaurantProfil);