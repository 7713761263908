import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './Questionnaire.css'

function Questions(props) {

    const responses = props.question[0].reponse
    const [responseChoosen, setResponseChoosen] = useState()
    
    useEffect(()=>{
        setResponseChoosen(responses[0])
    },[responses])

    const handleChange = (value) => {
        setResponseChoosen(value)
    }
    const handleSubmitForm = () => {
        props.handleSubmitFormParent();
    }

    const handleNextPage = () => {
        props.handleSubmitUniqueResponseParent(responseChoosen)
        props.handleClickNextParent()
    }
    
    const handleRestart = () => {
        props.handleRestartParents()
    }
  
    let responsesToDisplay = <span className='question_text'>Clickez sur terminer pour enregistrez vos réponses</span>
    if(responses.length > 1){
        responsesToDisplay = 
        <form className='response_container'>
            {responses.map((reponse,i)=>{
                return (
                    <div  key={i} className='response'>
                        <input name='gender' onChange={(e)=>handleChange(e.target.id)} id={reponse} value={responseChoosen} type='radio'></input>
                        <span className='response_text' >{reponse}</span> 
                    </div>
                )
            })}
        </form> 
    }
    
    let buttonToDisplay = 
        <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <button onClick={handleRestart} className='questionnaire_button'>Recommencer</button>
            <button onClick={()=>handleNextPage()} className='questionnaire_button'>Suivant</button> 
        </div>

    if(props.isFirst){
        buttonToDisplay = 
            <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <div style={{width:'150px'}}></div>
                <button onClick={handleNextPage} className='questionnaire_button'>Suivant</button> 
            </div>
    }

    if( props.isLast){
        buttonToDisplay =
            <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}> 
                <button onClick={handleRestart} className='questionnaire_button'>Recommencer</button>
                <Link className='questionnaire_link' to='/modifier/monprofil'><button onClick={handleSubmitForm} className='questionnaire_button'>Terminer</button></Link>
            </div> 
    }

    return (
        <>
        <div className='question_container'>
           <span className='question_text'>{props.question[0].question}</span> 
        </div>
        {responsesToDisplay}
        {buttonToDisplay}
        </>
    )
}

export default Questions;
