import React from 'react'
import './CropPicture.css'

function CropOutput() {
    return (
        <div style={{paddingTop:'100px', display:'flex', flexDirection:'column'}}>
        {/* <h3> Original image: </h3>
        <img
        src="https://hips.hearstapps.com/ghk.h-cdn.co/assets/17/30/2560x1280/landscape-1500925839-golden-retriever-puppy.jpg?resize=480:*"
        />
        <h3> Cropped image: </h3>
        <div class="cropped">
        <img
        src="https://hips.hearstapps.com/ghk.h-cdn.co/assets/17/30/2560x1280/landscape-1500925839-golden-retriever-puppy.jpg?resize=480:*"
        />
        </div>
        <h3> Original image: </h3>
        <img
        src="https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000"
        />
        <h3> Cropped image: </h3>
        <div class="cropped">
        <img
        src="https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000"
        />
       
        </div> */}
         <div>
            <img className='image' src='https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000' />
        </div>
        <div>
            <img className='image' src='https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000' />
        </div>
    </div>
    )
}

export default CropOutput;
