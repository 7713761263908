import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { db } from '../firebase';
import './TalentProfil.css'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { Grid, IconButton } from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn';
function TalentProfilView() {
    
    const { profilId } = useParams();
    const [talent, setTalent] = useState()
    const [experiences, setExperiences] = useState([])
    const [formations, setFormations] = useState([])
    useEffect(()=>{
        let docRef = db.collection("users").doc(profilId);

        docRef.get().then(function(doc) {
            if (doc.exists) {
                setTalent(doc.data());
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });

        let expRef = db.collection('users').doc(profilId).collection('experiences')

        expRef.onSnapshot((snapshot)=>(
          setExperiences(snapshot.docs.map(doc=>doc.data()))
        ))

        let formationsRef = db.collection('users').doc(profilId).collection('formations')

        formationsRef.onSnapshot((snapshot)=>(
          setFormations(snapshot.docs.map(doc=>doc.data()))
        ))

    },[])


  
    let birth = talent?.birthdate.toDate()
    if(birth){
        var dd = String(birth.getDate()).padStart(2, '0');
        var mm = String(birth.getMonth() + 1).padStart(2, '0'); 
        var yyyy = birth.getFullYear(); 
    }

    function calculate_age(dob) { 
        var diff_ms = Date.now() - dob.getTime();
        var age_dt = new Date(diff_ms); 
      
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    return (
        <div>
            <div className='talentProfil'>
                <Grid container spacing={4} className='talentProfil_header' >
                    <Grid item >
                        <img className='talentProfil_profilPicture' src={talent?.src}></img>
                    </Grid>
                    <Grid item  style={{display:'flex', flexDirection:'column'}}>
                        <span className='talentProfil_headerTitle'>{talent?.name} </span>
                        <span className='talentProfil_headerSubtitle'> {calculate_age(new Date(yyyy, mm, dd))} ans, Rennes</span> 
                    </Grid>
                </Grid>
                <ul className='talentProfil_body'>
                    <div className='talentProfil_motivation'>
                        <span className='talentProfil_bodyTitle'> Motivations </span>
                        <br/>
                        <br/>
                        
                        <span>{talent?.description}</span>
                        <br/>
                        <br/>
                    </div>
                    <span className='talentProfil_bodyTitle'> Actuellement </span>
                    <br/>
                    <li className='talentprofil_bodyli'>Recherche un {talent?.desiredContract} pour le poste de {talent?.desiredProfession}.</li>
                    <li className='talentprofil_bodyli'>Disponibilité : {talent?.availability}</li>
                    <li className='talentprofil_bodyli'>Disponibilité horaire : {talent?.hourlyAvailability}</li>
                    <div style={{display:'flex', alignItems:'center'}}>
                        <IconButton>
                            <LocationOnIcon></LocationOnIcon>
                        </IconButton>
                        <span>Voir la zone de recherche</span>
                    </div>
                    <br/>
                    
                    <span className='talentProfil_bodyTitle'> Experiences professionelles </span>
                    <br/>
                    <br/>
                    {experiences.map((exp,i)=>{
                        if(exp.isVerified){
                            return (
                                <li key={i} className='talentProfil_experience'>
                                    <p>{exp.job} pendant {exp.duration} chez {exp.firm} <VerifiedUserIcon className='icon'></VerifiedUserIcon> </p> 
                                    
                                </li>
                            )
                        }
                        else {
                            return (
                                <li key={i} className='talentProfil_experience'>
                                    <p> {exp.job} pendant {exp.duration} chez {exp.firm} </p>
                                </li>
                            )
                        }
                    })}
                    <br/>
                    <span className='talentProfil_bodyTitle'> Formations </span>
                    <br/>
                    <br/>
                    {formations.map((formation,i)=>{
                            return (
                                <li key={i} className='talentProfil_experience'>
                                    <p>Diplomé d'un {formation.titled} à {formation.school} en {formation.graduationYear}</p> 
                                    
                                </li>
                            )
                        })}
                </ul> 

                            
                {/* <h1> {talent.hourlyAvailability}</h1>
                <h1> {talent.averageRate}</h1>
                <h1> {talent.desiredProfession}</h1>
                <h1> {talent.desiredContract}</h1>
                <h1> {talent.src}</h1>
                <h1> {talent.name}</h1>
                <h1> {talent.description}</h1>
                <h1> {talent.hourlyAvailability}</h1>
                <h1> {talent.hourlyAvailability}</h1>
                <h1> {talent.hourlyAvailability}</h1>  */}
            </div>
        </div>
    )
}

export default TalentProfilView;
