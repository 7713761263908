import React, { useState, useEffect } from 'react'
import './SearchRestaurant.css'
import {db} from '../firebase'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import AnnonceCard from '../AnnonceCard/AnnonceCard'

function SearchRestaurant(props) {

    const [disconnect, setDisconnect] = useState(false)
    const [annonces, setAnnonces] = useState([])

    useEffect(() => {
       db.collection('annonces').onSnapshot(snapshot => {
           setAnnonces(snapshot.docs.map(doc=>({
               id : doc.id,
               data  : doc.data()
           })))
       })
   
    }, [])
    
    if(disconnect){
        return <Redirect to='/home'/>
    }
    else {
        return (
            <div>
                <div className='searchRestaurant'>
                    <div className='searchRestaurant_annonceContainer'>
                    {annonces.map((annonce,i)=>(
                        <AnnonceCard key={i} annonceDocId={annonce.id} annonceInfo={annonce.data}/> 
                    ))}
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { userToDisplay : state.user }
}

export default connect(
    mapStateToProps, 
    null
)(SearchRestaurant);