import React from 'react'

function SearchRestaurantByMatch() {
    return (
        <div>
           <h1>Hello from search by match</h1> 
        </div>
    )
}

export default SearchRestaurantByMatch
