import React, {useState, useEffect } from 'react'
import fire from '../firebase'
import { Redirect } from 'react-router-dom'
function Logout() {
    const [redirect, setRedirect] = useState(false)
    
    useEffect(() => {
        fire.auth().signOut();
        setRedirect(true)
    }, [])
        
    if(redirect){
        return <Redirect to='/home'/>
    }else {
        return (
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <h1>Déconnexion...</h1>
                <h1>Déconnexion...</h1>
                <h1>Déconnexion...</h1>
                <h1>Déconnexion...</h1>
                <h1>Déconnexion...</h1>
            
                <h1>Déconnexion...</h1>
                <h1>Déconnexion...</h1>
                <h1>Déconnexion...</h1>
                <h1>Déconnexion...</h1>
                <h1>Déconnexion...</h1>
            
                <h1>Déconnexion...</h1>
                <h1>Déconnexion...</h1>
                <h1>Déconnexion...</h1>
                <h1>Déconnexion...</h1>
                <h1>Déconnexion...</h1>
            
            </div>
        )
    }
}

export default Logout;
