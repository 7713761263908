import React, { useState, useEffect } from 'react'
import fire, {db} from '../firebase'
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux'
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { IconButton } from '@material-ui/core'
import './Signup.css'
import PlacesAutomcomplete, { geocodeByAddress, getLatLng} from 'react-places-autocomplete'


function SignupRestau(props) {

    const googleMapAPIKey = 'AIzaSyDKKr32fEfy3YRCenaJZN3u_5kwlGfvHIA'

    const [user , setUser] = useState('');
    const [name, setName] = useState('')
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [emailError, setEmailError]= useState('');
    const [passwordError, setPasswordError] = useState('')
    const [passwordType, setPasswordType] = useState('password')
    const [passwordConfirmType, setPasswordConfirmType] = useState('password')
    const [iconPassword, setIconPassword]= useState(<VisibilityIcon/>)
    const [iconPasswordConfirm, setIconPasswordConfirm]= useState(<VisibilityIcon/>)
    const [adress , setAdress] = useState('')
    const [coordinates, setCoordinates] = useState({lat : null, lng : null})
    const [zipCode, setZipCode] = useState()

    const togglePassword = () => {
        if(passwordType === 'password'){
            setPasswordType('text')
            setIconPassword(<VisibilityOffIcon/>)
        }
        else if (passwordType === 'text'){
            setPasswordType('password')
            setIconPassword(<VisibilityIcon/>)
        }
    }

    const toggleConfirmPassword = () => {
        if(passwordConfirmType === 'password'){
            setPasswordConfirmType('text')
            setIconPasswordConfirm(<VisibilityOffIcon/>)
        }
        else if (passwordConfirmType === 'text'){
            setPasswordConfirmType('password')
            setIconPasswordConfirm(<VisibilityIcon/>)
        }
    }

    const clearInputs = () => {
        setEmail('')
        setPassword('')
    }

    const clearErrors = () => {
        setEmailError('')
        setPasswordError('')
    }

    const handleSignup = () => {
        console.log(adress, coordinates)
        if(passwordConfirm === password){
            clearErrors();
            fire.auth().createUserWithEmailAndPassword(email, password)
            .then(cred=>{
                db.collection('users').add({
                    id : cred.user.uid,
                    name : name,
                    email : email,
                    type : 'restaurant',
                    coordinates : coordinates,
                    adress : adress, 
                    zipCode: zipCode
                })
            })
            .catch(err=>{
                switch(err.code){
                    case "auth/email-already-in-use":
                case 'auth/invalid-email':
                    setEmailError("L'email est invalide ou déjà utilisé")
                    break;
                case "auth/weak-password":
                    setPasswordError('Le mot de passe doit faire 6 caractères')
                    break;
                }
            })
        }else {
            setPasswordError("Les mots de passes ne correspondent pas")
        }   
    }

    useEffect(() => {
        fire.auth().onAuthStateChanged(user=>{
            if(user){
                clearInputs();
                setUser(user);
            }else{
                setUser('')
            }
        })
    }, [])

    const handleSelect = async (value) => {
        
        setAdress(value)
        const results = await geocodeByAddress(value)

        console.log(results[0].address_components.length)

        for (let i=0; i<results[0].address_components.length;i++){
            if(results[0].address_components[i].types.includes('postal_code')){
                setZipCode(results[0].address_components[i].long_name)
            }
        }
        const latlng = await getLatLng(results[0])
      
        setCoordinates(latlng)
    }

    return (
        <>{user ? (
        <Redirect to='/restaurant'/>
        ):(
        <div>       
            <div className='signup_container'>
                <div className='signup'>
                    <span style={{paddingTop:'20px'}} className='signup_title'>Creer un compte restaurant</span>
                    <input className='signup_input' placeholder='Nom du restaurant' value={name} onChange={(e)=>setName(e.target.value)}></input>
                    <div style={{paddingTop:'20px'}}> 
                        {/* <input className='signup_input' placeholder='Adresse'></input> */}
                        <PlacesAutomcomplete value={adress} onChange={setAdress} onSelect={handleSelect}>
                            {({getInputProps, suggestions, getSuggestionItemProps, loading})=>(
                                <div>
                                    <input aria-expanded='false'{...getInputProps({placeholder:'Adresse du restaurant', className:'signup_input'} )}/>
                                    <div>
                                        {loading ? <div>...Loading</div> : null}
                                        {suggestions.map((suggestion,i) =>{
                                            const style = {
                                                backgroundColor : suggestion.active ? '#f9dda4' : '#fff'
                                            }

                                            return <div key={i} {...getSuggestionItemProps(suggestion, {style, className:'signup_suggestion'})} >{suggestion.description}</div>
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutomcomplete>
                    </div>
                    <div style={{paddingTop:'20px'}}> 
                        <input className='signup_input' placeholder='Email' value={email} onChange={(e)=>setEmail(e.target.value)}></input>
                    </div>
                    <span className='signup_error'>{emailError}</span>
                    <div className='signup_passwordInputContainer'>
                        <input type={passwordType} className='signup_input' placeholder='Mot de passe' value={password} onChange={(e)=>setPassword(e.target.value)}>
                        </input>
                        <IconButton className='signup_inputIcon' onClick={togglePassword}>
                            {iconPassword}
                        </IconButton>
                    </div>
                    <div className='signup_passwordInputContainer'>
                        <input type={passwordConfirmType} className='signup_input' placeholder='Confirmez votre mot de passe' value={passwordConfirm} onChange={(e)=>setPasswordConfirm(e.target.value)}>
                        </input>
                        <IconButton className='signup_inputIcon' onClick={toggleConfirmPassword}>
                            {iconPasswordConfirm}
                        </IconButton>
                    </div>
                    <span className='signup_error'>{passwordError}</span>
                    <button className='signup_button' onClick={handleSignup}>SignUp</button>
                </div>
            </div>
        </div>
        )}
        </>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        onSendFirstTime: function() { 
            dispatch( {type: 'addFirstTime', firstTime : true} ) 
        }
    }
}

export default connect(
    null, 
    mapDispatchToProps
)(SignupRestau);