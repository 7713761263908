import React from 'react'
import './ContactUs.css'
import emailjs from 'emailjs-com';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

function ContactUs() {

    function sendEmail(e) {
        e.preventDefault();
        console.log(e.target)
    
        emailjs.sendForm('service_5kiqkeo', "template_sv77vqa", e.target, "user_VKLBaSnkgJ0xwHfKO49BT")
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });

        emailjs.sendForm('service_5kiqkeo', "template_4qgrntn", e.target, "user_VKLBaSnkgJ0xwHfKO49BT")
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
      }

    return (
        <div className='contactus'>
            <div className='contactus_form'>
                <span className='contactus_title'>Contactez-nous</span>
                <div className='contactus_icon'>
                    <LocationOnIcon/><span>6 rue Pihet, 75011, Paris</span>
                </div>
                <div className='contactus_icon'>
                    <MailIcon/><span>contact@onatray.com</span>
                </div>
                <div className='contactus_icon'>
                    <PhoneIphoneIcon/><span>+33628462581</span>
                </div>
                <span className='contactus_description'>Envoyez nous un message pour signalez un problème, demandez une collaboration, ou toute autre demande.</span>
                <form className="contact-form" onSubmit={sendEmail}>
                    <input type="hidden" name="contact_number" />
                    <input className='contactus_input' placeholder='Nom' type="text" name="to_name" />
                    <input className='contactus_input' placeholder='email' type="email" name="to_email" />
                    <textarea placeholder='Votre message' className='contactus_input' name="message" />
                    <input className='contactus_button' type="submit" value="Send" />
                </form>
            </div>
        </div>
    )
}

export default ContactUs;
