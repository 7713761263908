import firebase from 'firebase';


const fire = firebase.initializeApp({
    apiKey: "AIzaSyCOb1MQRHNFeKX4GZZizNMf0EcuEOAA2F8",
    authDomain: "onatray-3e95a.firebaseapp.com",
    projectId: "onatray-3e95a",
    storageBucket: "onatray-3e95a.appspot.com",
    messagingSenderId: "531032670001",
    appId: "1:531032670001:web:7f926d9c5554780096ba10",
    measurementId: "G-HWJ98V44EH"
});

export const db = fire.firestore();
export const storage = fire.storage();

export default fire ; 
