import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { db } from '../firebase'


function TalentProfil({userToDisplay}) {
    
    const docId = userToDisplay?.docId
    const [talent, setTalent] = useState()
    const [formations, setFormations] = useState([])
    const [experiences, setExperiences] = useState([])

    useEffect(()=>{
        let docRef = db.collection('users').doc(docId)

        docRef.get().then(function(doc) {
            if (doc.exists) {
                setTalent(doc.data())
                setFormations(doc.data().formations)
                setExperiences(doc.data().experiences)
            }else{
              console.log('no such document')
            }
        }).catch(function(error) {
        });
    },[])



    let birth = talent?.birthdate
    let birthdate = null;
    if (birth){
        birthdate = birth.toDate()
    }
    if(birthdate){
        var dd = String(birthdate.getDate()).padStart(2, '0');
        var mm = String(birthdate.getMonth() + 1).padStart(2, '0'); 
        var yyyy = birthdate.getFullYear(); 
    }
    function calculate_age(dob) { 
        var diff_ms = Date.now() - dob.getTime();
        var age_dt = new Date(diff_ms); 
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    if(!userToDisplay){
        return <Redirect to='/talent'/>
    }
    return (
        <div className='talentProfil'>
            <img className='talentProfil_profilePicture' src={talent?.src}></img>
            <div className='talentProfil_header'>
                <div>
                    <span className='talentProfil_title'>{talent?.name}</span>
                    <span className={`talentProfil_age ${birthdate && 'talentProfil_title'}`}> {birthdate && calculate_age(new Date(yyyy, mm, dd))}  ans</span>
                </div>
                <Link to='/talent/modifiermonprofil'><button className='talentProfil_button'>Edit profil</button></Link> 
            </div>
            <div className='talentProfil_description'>
                <span>{talent?.description}</span>
            </div>
            <div className='talentProfil_corpContainer'>
                <div className='talentProfil_recherche'>
                    <span className='talentProfil_title'>Ma recherche</span>
                    <span>Poste :  {talent?.jobChoosen} </span>
                    <span>Contrat : {talent?.contractChoosen}  </span>
                    <span>Horaire : {talent?.hourlyAvailabilityChoosen}</span>
                    <span>Date de disponibilité : {talent?.startDateChoosen}</span>
                
                </div>
                <div className='talentProfil_formation'>
                    <span className='talentProfil_title'>Mes formations</span>
                    {formations.map((formation,i)=>(
                        <div key={i} className='talentProfil_formationContainer'>
                            <span className='talentProfil_title' >Formation n° {i+1}</span>
                            <span>{formation.diploma}</span>
                            <span>{formation.schoolName}</span>
                            <span>{formation.endDate}</span>
                        </div>

                    ))}
                </div>
                <div className='talentProfil_formation'>
                    <span className='talentProfil_title'>Mes epxeriences</span>
                    {experiences.map((experience,i)=>(
                        <div key={i} className='talentProfil_formationContainer'>
                            <span className='talentProfil_title' >Formation n° {i+1}</span>
                            <span>{experience?.diploma}</span>
                            <span>{experience?.schoolName}</span>
                            <span>{experience?.endDate}</span>
                            <span>{experience?.startDate}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return { userToDisplay : state.user }
}

export default connect(
    mapStateToProps, 
    null
)(TalentProfil);
