import React, { useState, useEffect } from 'react'
import './RestaurantProfil.css'

import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import LaunchIcon from '@material-ui/icons/Launch';
import { IconButton } from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarOutline';


import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { db } from '../firebase'
import AnnonceCardView from '../AnnonceCard/AnnonceCardView'


function RestaurantProfil({userToDisplay}) {

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [modalSrc, setModalSrc] = useState('')
    const [restaurant, setRestaurant] = useState()
    const [annonces, setAnnonces] = useState([])
    const [restaurantPicture, setRestaurantPicture] = useState([])

    const rate = 4;
    const docId = userToDisplay?.docId
   
    useEffect(()=>{
        var docRef = db.collection("users").doc(docId);
        var imageRef = db.collection('users').doc(docId).collection('images')
        docRef.get().then(function(doc) {
            if (doc.exists) {
               
               setRestaurant(doc.data())
            } else {
                
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });

        if(docId){
            db.collection('annonces').where('id', '==', userToDisplay.id)
            .onSnapshot((snapshot)=>(
            setAnnonces(snapshot.docs.map(doc=>doc.data()))
            ))
            imageRef.onSnapshot((snapshot)=>(
                setRestaurantPicture(snapshot.docs.map(doc=>doc.data()))
            ))
        }
        
    },[])
    
    const openModal = (src) => {
        setIsModalVisible(true)
        setModalSrc(src)
    }
    const closeModal = (e) => {
        if(e.target.className === 'modal_container' )
        setIsModalVisible(false)
    }

    const displayNextPic = () => {
        let currentUrl = restaurantPicture.filter(item => item.url === modalSrc).map(item => item.url)
        let restaurantPictureTabOnly = [];
        for (let i=0; i<restaurantPicture.length;i++){
            restaurantPictureTabOnly.push(restaurantPicture[i].url)
        }
        
        let currentId = restaurantPictureTabOnly.indexOf(currentUrl[0])
        let nextPic ;
        if(currentId === (restaurantPicture.length)-1){
            nextPic = restaurantPicture[0]
        }
        else {
            nextPic = restaurantPicture[currentId+1] 
        }
        setModalSrc(nextPic.url)
    }

    const displayPreviousPic = () => {
        let currentUrl = restaurantPicture.filter(item => item.url === modalSrc).map(item => item.url)
        let restaurantPictureTabOnly = [];
        for (let i=0; i<restaurantPicture.length;i++){
            restaurantPictureTabOnly.push(restaurantPicture[i].url)
        }
        
        let currentId = restaurantPictureTabOnly.indexOf(currentUrl[0])
        let previousPic;
            if(currentId === 0 ){
                previousPic = restaurantPicture[(restaurantPicture.length)-1]
            }
            else{
                previousPic = restaurantPicture[currentId-1]
            }
            setModalSrc(previousPic.url)
    }
    let starsToDisplay = []
    for(let i=0;i<5;i++){
        if(i<rate){
            starsToDisplay.push(<StarIcon/>)
        }else{
            starsToDisplay.push(<StarOutlineIcon/>)
        }
    }
    //const coverPicture = restaurant?.coverPictureLink
    if(!userToDisplay){
        return <Redirect to='/restaurant'/>
    }
    else{
        return (
            <div className='profil'>
                <header className='profilCover'  style={{
                        backgroundSize:'cover',
                        backgroundImage:`url(${restaurantPicture[0]?.url})`,
                        backgroundPosition : 'center center'
                    }}>
                    <div className='profilCover_contents'>
                        <div className='profilCover_description'> 
                            <span className='profilCover_title'>
                                {restaurant?.name}
                            </span>
                            <div style={{paddingTop:'100px'}} className='profilInfo_items'> 
                                <IconButton>
                                    <LocationOnIcon/> 
                                </IconButton> 
                                <span className='profilInfo_item'>{restaurant?.adress}</span>
                            </div>
                            <div className='profilInfo_items'>  
                                <IconButton>
                                    <PhoneIcon/>
                                </IconButton> 
                                <span className='profilInfo_item'>{restaurant?.phoneNumber}</span>
                            </div>
                            <div className='profilInfo_items'>
                                <IconButton onClick={()=> window.open(`${restaurant?.websiteLink}`, "_blank")}>
                                    <LaunchIcon/>
                                </IconButton>
                                <span className='profilInfo_item'>Website</span>
                            </div>
                            <div className='profilInfo_items'>
                                <IconButton onClick={()=> window.open(`${restaurant?.instagramLink}`, "_blank")}>
                                    <InstagramIcon/>
                                </IconButton>
                                <span className='profilInfo_item'>Instagram</span>
                            </div>
                            <div className='profilInfo_items'>
                                <IconButton onClick={()=> window.open(`${restaurant?.facebookLink}`, "_blank")}>
                                    <FacebookIcon/>
                                </IconButton>
                                <span className='profilInfo_item'>Facebook</span>
                            </div>
                           
                        </div>
                    </div>
                    <div className='profilCover_fadebottom'/>
                </header>
                <div className='profilContent'>
                    <div className='ProfilContent_header'>
                    <h2 className='profilContent_title'>Découvrez {restaurant?.name} <div className='profil_starsContainer'>{starsToDisplay}</div> </h2> <Link className='profilContent_link' to='/modifier/monprofil'><button className='button'>Modifier profil</button></Link>
                    </div>
                    <h4 className='profilContent_description'>{restaurant?.description} </h4>
                    <h2 className='profilContent_title'>Galerie photo</h2>
                    <div className='profilRow'>
                        <div className='profilRow_posters'>
                            {restaurantPicture.map((picture, i) => (
                                <img 
                                onClick={()=> openModal(picture.url)}
                                key={i}
                                className='profilRow_poster'
                                src={picture.url} 
                                alt={i}/>
                            ))}
                        </div>
                    </div>
                    <h2 className='profilContent_title'>Vos Annonces</h2>
                            {/* <Grid container spacing={3} style={{display:'flex', justifyContent:'center'}}>  */}
                                <div className='profilContent_annonces'>
                                {annonces.map((annonce,i )=>(
                                    <AnnonceCardView key={i} annonceInfo={annonce} />
                                ))}
                                </div>
                            {/* </Grid> */}
                </div>
                <div onClick={(e)=>closeModal(e)} className={`profilModal ${isModalVisible && 'profilModal_show'}`}>
                    <div onClick={(e)=>closeModal(e)} className='modal_container'>
                        <img className='profilModal_content' src={modalSrc} alt='1'></img>
                        <ArrowForwardIosIcon onClick={()=>displayNextPic()} className='profilModal_nextButton'/>
                        <ArrowBackIosIcon onClick={()=>displayPreviousPic()} className='profilModal_previousButton'/>
                    </div>
                </div>
            </div>
        )
    }   
}

function mapStateToProps(state) {
    return { userToDisplay : state.user }
  }
    
  export default connect(
    mapStateToProps, 
    null
  )(RestaurantProfil);
