import React, { useState, useEffect } from "react";
import "./RestaurantAnnonce.css";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Grid } from "@material-ui/core";
import AnnonceCardView from "../AnnonceCard/AnnonceCardView";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { db } from "../firebase";
import firebase from "firebase";

function RestaurantAnnonce({ userToDisplay }) {
  const [isAnnonceFormOpen, setIsAnnonceFormOpen] = useState(false);
  const [isExtraFormOpen, setIsExtraFormOpen] = useState(false);
  const [isAnyFormOpen, setIsAnyFormOpen] = useState(false);

  const [newAnnonceTitle, setNewAnnonceTitle] = useState("");
  const [newAnnonceDescription, setNewAnnonceDescription] = useState("");
  const [newAnnonceJob, setNewAnnonceJob] = useState("");
  const [newAnnonceExperience, setNewAnnonceExperience] = useState("");
  const [newAnnonceContract, setNewAnnonceContract] = useState("");
  const [newAnnonceAvailability, setNewAnnonceAvailability] = useState("");
  const [newAnnonceHourlyAvailability,setNewAnnonceHourlyAvailability] = useState("");
  const [newAnnonceSalary, setNewAnnonceSalary] = useState("");

  const [newExtraTitle, setNewExtraTitle] = useState("");
  const [newExtraDescription, setNewExtraDescription] = useState("");
  const [newExtraJob, setNewExtraJob] = useState("");
  const [newExtraSchedule, setNewExtraSchedule] = useState("");
  const [newExtraDate, setNewExtraDate] = useState();
  const [newExtraSalary, setNewExtraSalary] = useState("");
  const [newExtraType, setNewExtraType] = useState("");

  const [annonces, setAnnonces] = useState([]);
  const [restaurant, setRestaurant] = useState([]);

  const docId = userToDisplay?.docId;
  //const id = userToDisplay?.id

  useEffect(() => {
    var docRef = db.collection("users").doc(docId);

    docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {    
          setRestaurant(doc.data());
        } 
        else {
         // alert('Erreur, veuillez vous reconnectez')
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });

    if (docId) {
      db.collection("annonces")
        .where("id", "==", userToDisplay.id)
        .onSnapshot((snapshot) =>
          setAnnonces(snapshot.docs.map((doc) =>({
              data:doc.data(),
              id:doc.id
            })))
        );
    }
  }, []);

  const submitAnnonceForm = (e) => {
    e.preventDefault();
    console.log(
      newAnnonceJob,
      newAnnonceContract,
      newAnnonceAvailability,
      newAnnonceExperience,
      newAnnonceTitle,
      newAnnonceDescription,
      newAnnonceHourlyAvailability,
      newAnnonceSalary
    );

    db.collection("annonces").add({
      title: newAnnonceTitle,
      contract: newAnnonceContract,
      availability: newAnnonceAvailability,
      experience: newAnnonceExperience,
      job: newAnnonceJob,
      description: newAnnonceDescription,
      hourlyAvailability: newAnnonceHourlyAvailability,
      salaray: newAnnonceSalary,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      name: restaurant.name,
      instagramLink: restaurant.instagramLink,
      facebookLink: restaurant.facebookLink,
      websiteLink: restaurant.websiteLink,
      adress: restaurant.adress,
      id: userToDisplay.id,
    });

    setNewAnnonceTitle("");
    setNewAnnonceDescription("");
    setNewAnnonceHourlyAvailability("");
    setNewAnnonceSalary("");
    setNewAnnonceJob("");
    setNewAnnonceExperience("");
    setNewAnnonceContract("");
    setNewAnnonceAvailability("");
  };

  const submitExtraForm = (e) => {
    e.preventDefault();
    console.log(
      newExtraTitle,
      newExtraDate,
      newExtraDescription,
      newExtraSalary,
      newExtraSchedule,
      newExtraJob
    );

    console.log(newExtraType);

    setNewExtraType("");
    setNewExtraTitle("");
    setNewExtraDate();
    setNewExtraDescription("");
    setNewExtraSalary("");
    setNewExtraSchedule("");
    setNewExtraJob("");
    setIsExtraFormOpen(false);
    setIsAnyFormOpen(false);
  };

  let annonceToDisplay = (
    <h3 className='annonce_text'>Vous n'avez pas encore ajouté d'annonce...</h3>
  );
  if (annonces.length > 0) {
    annonceToDisplay = annonces.map((annonce, i) => (
      <AnnonceCardView key={i} annonceDocId={annonce.id} annonceInfo={annonce.data} />
    ));
  }

  const advice = [
    {
      title: "Pour une annonce réussite, voici quelques conseils..",
      firstAdvice:
        "Veuillez à remplir le maximum de champs pour filtrer au maximum et recevoir uniquement des CV pertinents",
      secondAdvice:
        "Dans la description, n'hésitez pas à precisez les missions ou le comportement attendu de la personne recruter, ainsi que les jours de repos qu'il lui seront assigné",
    },
    {
      title: "Pour une alerte extra réussite voici quelques conseils..",
      firstAdvice:
        "N'envoyez pas l'alerte trop longtemps avant votre besoin d'extra, pour que les employés puissent connaitre leur disponibilités",
      secondAdvice:
        "Renseignez un salaire le plus précis possible, ceci étant la première motivation d'une personne cherchant à faire des extras",
    },
  ];

  let adviceToDisplay;
  if (isAnnonceFormOpen) {
    adviceToDisplay = advice[0];
  } else if (isExtraFormOpen) {
    adviceToDisplay = advice[1];
  }

  const openAnnonceForm = () => {
    if (isExtraFormOpen) {
      setIsExtraFormOpen(false);
    }
    if (!isAnnonceFormOpen) {
      setIsAnnonceFormOpen(true);
      setIsAnyFormOpen(true);
    } else if (isAnnonceFormOpen) {
      setIsAnyFormOpen(false);
      setIsAnnonceFormOpen(false);
    }
  };

  const openExtraForm = () => {
    if (isAnnonceFormOpen) {
      setIsAnnonceFormOpen(false);
    }
    if (!isExtraFormOpen) {
      setIsExtraFormOpen(true);
      setIsAnyFormOpen(true);
    } else if (isExtraFormOpen) {
      setIsAnyFormOpen(false);
      setIsExtraFormOpen(false);
    }
  };

  if (!userToDisplay) {
    return <Redirect to='/restaurant' />;
  } else {
    return (
      <div className='container'>
          <div className='app_fakeDiv'></div>
          <div className='app'>
            <div className='app_buttonContainer'>
              <div className={`search_item ${isAnnonceFormOpen && 'search_itemActive'}`}>
                <p onClick={openAnnonceForm}>Publier une annonce</p>
              </div>
              <div className={`search_item ${isExtraFormOpen && 'search_itemActive'}`}>
                <p onClick={openExtraForm}>Publier une alerte extra</p>
              </div>
            </div>
            <div>
              <form
                className={`app_annonceForm ${
                  isAnnonceFormOpen && "app_annonceShow"
                }`}
              >
                <div className='form_container'>
                  <div
                    className={`adviceMobileHide ${
                      isAnyFormOpen && "adviceMobileShow"
                    }`}
                  >
                    <ul>
                      <p className='advice_text'>{adviceToDisplay?.title}</p>
                      <li className='advice_text'>
                        {adviceToDisplay?.firstAdvice}
                      </li>
                      <br />
                      <li className='advice_text'>
                        {adviceToDisplay?.secondAdvice}
                      </li>
                    </ul>
                  </div>
                  <input
                    value={newAnnonceTitle}
                    onChange={(e) => setNewAnnonceTitle(e.target.value)}
                    className='app_annonceInput'
                    placeholder='Titre'
                  ></input>
                  <div style={{paddingTop:'20px'}}>
                  <textarea
                    maxLength='160'
                    value={newAnnonceDescription}
                    onChange={(e) => setNewAnnonceDescription(e.target.value)}
                    placeholder='Description'
                    className='app_annonceInput app_annonceArea'
                  ></textarea>
                  </div>
                   <div style={{ display: "flex", paddingTop: "20px" }}> 
                    <select
                      defaultValue='Poste recherché'
                      className='app_annonceSelect'
                      //value={newAnnonceJob}
                      onChange={(e) => setNewAnnonceJob(e.target.value)}
                    >
                      <option value='Poste recherché' disabled>
                        Poste
                      </option>
                      <optgroup  label='En cuisine'>
                        <option className='app_annonceSelect_color' value='Chef'>Chef</option>
                        <option value='Chef de partie'>Chef de partie</option>
                        <option value='Commis'>Commis</option>
                        <option value='Plongeur'>Plongeur</option>
                      </optgroup>
                      <optgroup label='En salle'>
                        <option value='Directeur'>Directeur</option>
                        <option value='Manager'>Manager</option>
                        <option value='Chef de rang'>Chef de rang</option>
                        <option value='Runner'>Runner</option>
                        <option value='Barman'>Barman</option>
                      </optgroup>
                    </select>
                    </div>
                    <div style={{paddingTop:'20px'}}>
                      <select
                        defaultValue='Type de contrat'
                        onChange={(e) => setNewAnnonceContract(e.target.value)}
                        placeholder='Type de contrat'
                        className='app_annonceSelect'
                      >
                        <option value='Type de contrat' disabled>
                          Type de contrat
                        </option>
                        <option value='CDI'>CDI</option>
                        <option value='CDD'>CDD</option>
                        <option value='Extra'>Extra</option>
                        <option value='Saisonnier'>Saisonnier</option>
                      </select>
                      </div>
                  <div style={{ paddingTop: "20px" }}>
                    <select
                      defaultValue='Disponibilité'
                      onChange={(e) =>
                        setNewAnnonceAvailability(e.target.value)
                      }
                      placeholder='Experience requise'
                      className='app_annonceSelect'
                    >
                      <option defaultValue='Disponibilité' disabled>
                        Disponibilité
                      </option>
                      <option value='Immédiate'>Immédiate</option>
                      <option value='Sous 1 mois'>Sous 1 mois</option>
                      <option value='Sous 2 mois'>Sous 2 mois</option>
                    </select>
                    </div>
                    <div style={{paddingTop:'20px'}}>
                      <select
                        defaultValue='Horaire'
                        onChange={(e) =>
                          setNewAnnonceHourlyAvailability(e.target.value)
                        }
                        placeholder='Experience requise'
                        className='app_annonceSelect'
                      >
                        <option value='Horaire' disabled>
                          Horaire
                        </option>
                        <option value='Service du midi'>Service du midi</option>
                        <option value='Service du soi'>Service du soir</option>
                        <option value='Midi et après-midi'>
                          Midi et après-midi
                        </option>
                        <option value='Après-midi et soir'>
                          Après-midi et soir
                        </option>
                        <option value='Flexible'>Flexible</option>
                      </select>
                    </div>
                  <div style={{paddingTop: "20px" }}>
                    <select
                      defaultValue='Experience requise'
                      onChange={(e) => setNewAnnonceExperience(e.target.value)}
                      className='app_annonceSelect'
                    >
                      <option value='Experience requise' disabled>
                        Experience
                      </option>
                      <option value='Aucune'>Aucune</option>
                      <option value='+1 ans'>+1 ans</option>
                      <option value='+2 ans'>+2 ans</option>
                      <option value='+3 ans'>+3 ans</option>
                    </select>
                  </div>
                  <div style={{paddingTop:'20px'}}>
                    <select
                      defaultValue='Fourchette salaire'
                      onChange={(e) => setNewAnnonceSalary(e.target.value)}
                      className='app_annonceSelect'
                    >
                      <option value='Fourchette salaire' disabled>
                        Salaire
                      </option>
                      <option value='Aucune'>1k-1.2k</option>
                      <option value='+1 ans'>1.2-1.5k</option>
                      <option value='+2 ans'>1.5-1.7</option>
                      <option value='+3 ans'>+2k</option>
                    </select>
                  </div>
                  <div className='app_buttonContainer'>
                    <button
                      onClick={(e) => submitAnnonceForm(e)}
                      className='app_button'
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </form>
              <form
                className={`app_annonceForm ${
                  isExtraFormOpen && "app_annonceShow"
                }`}
              >
                <div className='form_container'>
                  <div
                    className={`adviceMobileHide ${
                      isAnyFormOpen && "adviceMobileShow"
                    }`}
                  >
                    <ul>
                      <p className='advice_text'>{adviceToDisplay?.title}</p>
                      <li className='advice_text'>
                        {adviceToDisplay?.firstAdvice}
                      </li>
                      <br />
                      <li className='advice_text'>
                        {adviceToDisplay?.secondAdvice}
                      </li>
                    </ul>
                  </div>
                  <input
                    value={newExtraTitle}
                    onChange={(e) => setNewExtraTitle(e.target.value)}
                    className='app_annonceInput'
                    placeholder='Titre'
                  ></input>
                  <div style={{paddingTop:'20px'}}>
                    <input
                        value={newExtraJob}
                        onChange={(e) => setNewExtraJob(e.target.value)}
                        className='app_annonceInput'
                        placeholder='Poste recherché'
                      ></input>
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems:'center',
                      paddingTop: "15px",
                      paddingBottom: "20px",
                    }}
                  > */}
                    {/* <span className='app_annonceRadioLabel'>En salle ou en cuisine ? </span>
                    <span className='app_annonceRadio'>En salle</span>
                    <input
                      className='app_inputRadio'
                      value={newExtraType}
                      onChange={(e) => setNewExtraType(e.target.value)}
                      type='radio'
                      id='En salle'
                      name='gender'
                      value='En salle'
                    ></input>
                    <span className='app_annonceRadio'>En cuisine</span> 
                    <input
                      className='app_inputRadio'
                      value={newExtraType}
                      onChange={(e) => setNewExtraType(e.target.value)}
                      type='radio'
                      id='En cuisine'
                      name='gender'
                      value='En cuisine'
                    ></input> */}
                  {/* </div> */}
                  <div style={{paddingTop:'20px'}}> 
                    <input
                      value={newExtraSalary}
                      onChange={(e) => setNewExtraSalary(e.target.value)}
                      className='app_annonceInput'
                      placeholder='Salaire'
                    ></input>
                  </div>
                  <span className='label_horaire'>Horaires</span>
                  <div style={{ paddingTop: "10px", display:'flex' }}>
                    <input
                      type='time'
                      value={newExtraSchedule}
                      onChange={(e) => setNewExtraSchedule(e.target.value)}
                      className='app_annonceInputTime'
                      placeholder='Horaire'
                    ></input>
                    <input
                      type='time'
                      value={newExtraSchedule}
                      onChange={(e) => setNewExtraSchedule(e.target.value)}
                      className='app_annonceInputTime'
                      placeholder='Horaire'
                    ></input>
                  </div>
                  <div className='date_container'>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant='inline'
                        format='MM/dd/yyyy'
                        margin='normal'
                        id='date-picker-inline'
                        label='Date'
                        value={newExtraDate}
                        onChange={(date) => setNewExtraDate(date)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className='app_buttonContainer'>
                    <button
                      onClick={(e) => submitExtraForm(e)}
                      className='app_button'
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        <h3>Vos Annonces : </h3>
        <Grid
          container
          spacing={3}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {annonceToDisplay}
        </Grid>
      </div>
    );
  }
}


function mapStateToProps(state) {
  return { userToDisplay: state.user };
}

export default connect(mapStateToProps, null)(RestaurantAnnonce);
