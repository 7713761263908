import React, { useState } from 'react'
import Questions from './Questions'
import './Questionnaire.css'

function Questionnaire() {

    const questions = [
        {question : 'Quelle type de restaurant êtes vous ? ', reponse :['brasserie','pizerria','creperie','gastronomique','bistrot']},
        {question : "Quelle est l'ambiance de votre restaurant", reponse :['Calme','Sobre','Branché','Animé']},
        {question : "Quelle type de clientèle recevez-vous ?", reponse :['Étudiante',"Groupe d'amis",'Famille','Clientèle de quartier', "Touristique", "Luxe"]},
        {question : 'Quelle type de cuisine pratiqué vous ? ', reponse :['Francaise','Asiatique','Italienne','Exotique']}, 
        {question : 'Quelle profil de personnel souhaité vous recrutez en cuisine ? ', reponse :['Jeune à former','experiementé','très experiementé',]},
        {question : 'Quelle profil de personnel souhaité vous recrutez en cuisine ? ', reponse :['Spécialisé dans votre type de cuisine','Polyvalent',"Peu m'importe",]},
        {question : "Quelle est la moyenne d'age de votre équipe en salle ?", reponse :['20-30ans','30-40ans',"+ de 40 ans",]},
        {question : "Merci d'avoir répondu au questionnaire", reponse : ['']}]
    

    const [currentPage, setCurrentPage] = useState(0); 
    const [responses, setResponses] = useState([]);

    const currentQuestion = questions.slice(currentPage, currentPage+1)

    const handleNextPage = () => {
        setCurrentPage(currentPage+1)
    }
    const handleRestart = () => {
        setCurrentPage(0)
        setResponses([])
    }

    const handleSubmitUniqueResponse = (value) => {
        console.log(value)
        setResponses([...responses, value])
    }
    
    const submitForm = () => {
        console.log('submit form', responses)
    }

  
   let questionToDisplay = <Questions handleSubmitUniqueResponseParent={handleSubmitUniqueResponse} handleClickNextParent={handleNextPage} handleRestartParents={handleRestart} question={currentQuestion} isFirst={false} isLast={false}/>
   if( currentPage+1 === questions.length){
       questionToDisplay = <Questions handleSubmitFormParent={submitForm} handleClickNextParent={handleNextPage} handleRestartParents={handleRestart} question={currentQuestion} isFirst={false} isLast={true}/>
    }
    if(currentPage === 0){
        questionToDisplay = <Questions handleSubmitUniqueResponseParent={handleSubmitUniqueResponse} handleClickNextParent={handleNextPage} handleRestartParents={handleRestart} question={currentQuestion} isFirst={true} isLast={false}/>
    }
    return (
        <div className='questionnaire'>
            <div className='questionnaire_box'>
                {questionToDisplay}
            </div> 
        </div>
    )
}

export default Questionnaire
