import React from 'react'
import './Home.css'
import { Link } from 'react-router-dom'

function Home() {
    return (
        <>
            <div className='home'>
                <div className='home_top'>
                    <h1>Une communauté pour les restaurants et leur personnels</h1>
                    <h2>Pour les restaurants</h2>
                    <h4>Recrutez du personnel fiable et adapté à votre restaurants</h4>
                    <br/>
                    <h2>Pour le personnel</h2>
                    <h4>Mettez en valeur vos diplôme et votre experience et trouver un travail à votre juste valeur</h4>
                    <div className='home_topButtons'>
                    <Link to='/signup/restaurant'> <button className='home_button'>Un restaurant</button></Link>
                    <Link to='/signup/talent'><button className='home_button'>un talent</button> </Link>
                    </div>
                </div>
           </div>
        </>
    )
}

export default Home;
