import React, { useState } from 'react'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { IconButton } from '@material-ui/core'
function Formation(props) {
 
    let formation = props.formation
    const [firmName, setFirmName] = useState(formation?.schoolName)
    const [diploma, setDiploma] = useState(formation?.diploma)
    const [endDate, setEndDate] = useState(formation?.endDate)
    const handleChangeSchoolName = (e) => {
        setFirmName(e.target.value)
        props.handleChangeSchoolNameParent(e.target.value, props.index)
    }

    const handleChangeDiploma = (e) => {
        setDiploma(e.target.value)
        props.handleChangeDiplomaParent(e.target.value, props.index)
    }
    const handleChangeEndDate = (e) => {
        
        setEndDate(e.target.value)
        props.handleChangeEndDateParent(e.target.value, props.index)
    }

    const handleDelete = () => {
        props.handleDeleteParent()
    }
    if(props.isLast){
        return (
            <div className='experience_container'>
                <IconButton onClick={handleDelete} className='experience_deleteIcon'><DeleteForeverIcon/></IconButton>
                <span className='experience_title'>Formations n°{props.index + 1}</span>
                <div className='experience_inputContainer'>
                    <div style={{paddingTop:'20px'}}>
                        <input className='experience_input' value={firmName} onChange={(e)=>handleChangeSchoolName(e)} placeholder="Nom de l'établissement"></input>
                    </div>
                    <div style={{paddingTop:'20px'}}>
                        <input className='experience_input' value={diploma} onChange={(e)=>handleChangeDiploma(e)} placeholder="Nom du dilôme"></input>
                    </div>    
                    <div style={{paddingTop:'20px'}}>
                        <input type='date' className='experience_input' value={endDate} onChange={(e)=>handleChangeEndDate(e)} placeholder="Date d'obtention"></input>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className='experience_container'>
                {/* <IconButton onClick={handleDelete} className='experience_deleteIcon'><DeleteForeverIcon/></IconButton> */}
                <span className='experience_title'>Formations n°{props.index + 1}</span>
                <div className='experience_inputContainer'>
                    <div style={{paddingTop:'20px'}}>
                        <input className='experience_input' value={firmName} onChange={(e)=>handleChangeSchoolName(e)} placeholder="Nom de l'établissement"></input>
                    </div>
                    <div style={{paddingTop:'20px'}}>
                        <input className='experience_input' value={diploma} onChange={(e)=>handleChangeDiploma(e)} placeholder="Nom du diplôme"></input>
                    </div>    
                    <div style={{paddingTop:'20px'}}>
                        <input type='date' className='experience_input' value={endDate} onChange={(e)=>handleChangeEndDate(e)} placeholder="Date de fin"></input>
                    </div>
                </div>
            </div>
    )
}

export default Formation


