import React, { useState, useEffect } from 'react'
import SearchRestaurant from './SearchRestaurant'
import SearchRestaurantByMatch from './SearchRestaurantByMatch'
import fire, {db} from '../firebase'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import './SearchRestaurant.css'

function SearchRestaurantContainer(props) {

    const [disconnect, setDisconnect] = useState();
    const [isGlobalSearchOpen, setIsGlobalSearchOpen] = useState(true)
    const [isMatchingSearchOpen, setIsMatchinSearchOpen] = useState(false)

    useEffect(() => {
        fire.auth().onAuthStateChanged(user=>{
            if(user){ 
                db.collection('users').where("id", "==", user.uid)
                .onSnapshot(snapshot=>{
                    props.onSendUser({id: user.uid, type : 'talent', docId : snapshot.docs[0].id})    
                })
            }
            else{
                setDisconnect(true)
                props.onSendUser(null)
            }
        })
    }, [])

    const openGlobalSearch = () => {
        if(isMatchingSearchOpen){
            setIsMatchinSearchOpen(false)
        }
        setIsGlobalSearchOpen(true)
    }

    const openMatchingSearch = () => {
        if(isGlobalSearchOpen){
            setIsGlobalSearchOpen(false)
        }
        setIsMatchinSearchOpen(true)
    }

    let childrenToDisplay = <SearchRestaurant/>

    if(isGlobalSearchOpen){
        childrenToDisplay = <SearchRestaurant />
    }else if (isMatchingSearchOpen){
        childrenToDisplay = <SearchRestaurantByMatch/>
    }

    if(disconnect){
        return <Redirect to='/home' />
    }
    else{
        return (
            <div className='searchRestaurantContainer'>
                <div className='searchRestaurantContainer_buttons'>
                    <div className={`search_item ${isGlobalSearchOpen && 'search_itemActive'}`}>
                        <p onClick={openGlobalSearch}>Toutes les annonces</p>
                    </div>
                    <div className={`search_item ${isMatchingSearchOpen && 'search_itemActive'}`}>
                        <p onClick={openMatchingSearch}>Annonces qui me correspondent</p>
                    </div>

                </div>
                {childrenToDisplay}
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
      onSendUser: function(user) { 
          dispatch( {type: 'addUser', user} ) 
      }
    }
  }
  
export default connect(
    null, 
    mapDispatchToProps
)(SearchRestaurantContainer);

