import React, { useState, useEffect } from 'react'
import Formation from './Formation'
import { connect } from 'react-redux'
import { db } from '../../firebase'

function Formations({userToDisplay}) {

    const docId = userToDisplay?.docId
    const [formations, setFormations] = useState([])

    useEffect(()=>{
        let docRef = db.collection('users').doc(docId)
        docRef.get().then(function(doc) {
            if (doc.exists) {
                setFormations(doc.data().formations)
            }else{
                setFormations([{schoolName : '', endDate : '', diploma : ''}])
            }
        }).catch(function(error) {
        });
    },[])

    const addFormations = () => {
    setFormations([...formations, {schoolName : '', endDate : '', diploma : ''}])
    }

    const handleChangeSchoolName = (value, i) => {
        formations[i].schoolName = value
    }

    const handleChangeDiploma = (value, i) => {
        formations[i].diploma = value
    }

    const handleChangeEndDate = (value, i) => {
        
        console.log(typeof JSON.stringify(value))
        
        formations[i].endDate = value
    }


    const handleSubmit = () => {
        db.collection('users').doc(docId).update({formations : formations})
    }

    const handleDelete = () => {
        setFormations(formations.slice(0,formations.length-1))
    }
  
    return (
        <div className='experiences_container'>
            {formations.map((formation, i)=>{
                if(i+1 === formations.length){
                    return <Formation index={i} 
                    formation={formation}
                    handleChangeSchoolNameParent={handleChangeSchoolName} handleChangeDiplomaParent={handleChangeDiploma} handleChangeEndDateParent={handleChangeEndDate} handleDeleteParent={handleDelete}
                    key={i} isLast={true}/>
                }
                return <Formation index={i} 
                formation={formation}
                handleChangeSchoolNameParent={handleChangeSchoolName} handleChangeDiplomaParent={handleChangeDiploma} handleChangeEndDateParent={handleChangeEndDate} handleDeleteParent={handleDelete}
                key={i} isLast={false}/>
            })} 
            
            <button className='experiences_buttonAdd' onClick={addFormations}>Ajouter une formation</button>
            <button className='experiences_button' onClick={handleSubmit}>Valider</button>
        </div>
    )
}

function mapStateToProps(state) {
    return { userToDisplay : state.user }
}

export default connect(
    mapStateToProps, 
    null
)(Formations);

