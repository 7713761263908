import React from 'react'
import './AnnonceCard.css'
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import { IconButton } from '@material-ui/core'
import { Link } from 'react-router-dom'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { db } from '../firebase'

function AnnonceCardView({annonceInfo, annonceDocId}) {
    console.log(annonceDocId)
    const deleteAnnonce = () => {
        let annonceRef = db.collection('annonces').doc(annonceDocId)
       
        if(window.confirm('Voulez-vous vraiment supprimer cette alerte ? ')){
            annonceRef.delete();
        }else {

        }
      
    }

    return (
        <div className='annonceCard'>
            <div className='annonceCard_header'>
                <span className='annonceCard_headerTitle'>{annonceInfo?.title}</span>
                <div className='annonceCard_deleteIcon'>
                    <IconButton onClick={deleteAnnonce}>
                        <DeleteForeverIcon/>
                    </IconButton>
                </div>
            </div>
            <div className='annonceCard_content'>
                <div className='annonceCard_name'>
                    <div className='annonceCard_nameLeft'>
                        <span className='annonceCard_nameText'>{annonceInfo?.name}</span>
                        {/* <IconButton onClick={()=> window.open(`${annonceInfo?.websiteLink}`, "_blank")} ><LaunchIcon/></IconButton> */}
                    </div>
                    <div className='annonceCard_nameRight'>
                        <IconButton onClick={()=> window.open(`${annonceInfo?.facebookLink}`, "_blank")} ><FacebookIcon/></IconButton>
                        <IconButton onClick={()=> window.open(`${annonceInfo?.instagramLink}`, "_blank")} ><InstagramIcon/></IconButton>
                    </div>
                </div>
                <div className='annonceCard_description'>
                    <span className='annonceCard_descriptionTitle'>Description : </span>
                    <span>{annonceInfo?.description}</span>
                </div>
                <div className='annonceCard_items'>
                    <div className='annonceCard_item'>
                        <span className='annonceCard_itemTitle'>Poste recherché : </span> 
                        <span className='annonceCard_itemContent'> {annonceInfo?.job}</span>
                    </div>
                    <div className='annonceCard_item'>
                        <span className='annonceCard_itemTitle'>Type de contrat :  </span> 
                        <span className='annonceCard_itemContent'> {annonceInfo?.contract}</span>
                    </div>
                    <div className='annonceCard_item'>
                        <span className='annonceCard_itemTitle'>Disponible : </span> 
                        <span className='annonceCard_itemContent'> {annonceInfo?.availability}</span>
                    </div>
                    <div className='annonceCard_item'>
                        <span className='annonceCard_itemTitle'>Experience requise : </span> 
                        <span className='annonceCard_itemContent'> {annonceInfo?.experience}</span>
                    </div>
                    <div className='annonceCard_item'>
                        <span className='annonceCard_itemTitle'>Horaire : </span> 
                        <span className='annonceCard_itemContent'> {annonceInfo?.hourlyAvailability}</span>
                    </div>
                </div>
                <div className='annonceCard_footer'>
                    <Link to='#'>
                        <button className='annonceCard_button'  >Postuler</button>
                    </Link>
                    <Link to='#'>
                        <button className='annonceCard_button' >Voir le restaurant</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default AnnonceCardView;
