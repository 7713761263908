import React, { useState, useEffect } from 'react'
import './TalentCard.css'
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { Link } from 'react-router-dom'
import { db } from '../firebase'
import { connect } from 'react-redux'
import { IconButton } from '@material-ui/core'

function TalentCard({talent, id, userToDisplay, fav, favouriteDocId}) {
  
    const [favCount, setFavCount] = useState(3)
    const [isfavourite, setIsFavourite] = useState(fav)
    const docId = userToDisplay?.docId
    
    //console.log(talent.id)

    const addToFavourite = () => {
        let favouriteRef = db.collection('users').doc(docId).collection('favoris')

        console.log(talent.desiredContract)
        if(!isfavourite){
            favouriteRef.doc().set({
                id : talent?.id,
                src : talent?.src,
                name : talent?.name,
                description : talent?.description,
                desiredContract : talent?.desiredContract,
                desiredProfession : talent?.desiredProfession,
                availability : talent?.availability,
                hourlyAvailability : talent?.hourlyAvailability,
                birthdate : talent?.birthdate
            })
            setIsFavourite(true)
        }
        else if(isfavourite){
            console.log(talent.id)
            favouriteRef.doc(talent.favouriteDocId || favouriteDocId).delete();
            setIsFavourite(false)
        }
    }
    
    let birth = talent?.birthdate.toDate()
    if(birth){
        var dd = String(birth.getDate()).padStart(2, '0');
        var mm = String(birth.getMonth() + 1).padStart(2, '0'); 
        var yyyy = birth.getFullYear(); 
    }

    function calculate_age(dob) { 
        var diff_ms = Date.now() - dob.getTime();
        var age_dt = new Date(diff_ms); 
      
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    useEffect(() => {
        setIsFavourite(fav) 
    }, [fav])
 
    if(isfavourite === undefined){
        return ( <h1>Loading</h1>)
    }
    else if(isfavourite){
        return (
            <div className ='card'>
                <div className='card_background'>
                    <img className='card_image' src={talent.src} alt='profilPicutre'/>
                </div> 
                <div className='card_info'>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <h4 className='card_title'>{talent.name}, {talent && calculate_age(new Date(yyyy, mm, dd))}  ans </h4>
                        <div className='card_infoHeart'>
                            <IconButton onClick={addToFavourite}>
                                <FavoriteIcon className='heart_icon'/>
                            </IconButton> 
                            <span className='card_textHeart'>({favCount})</span>
                        </div>
                    </div>  
                    <p className='card_text'>{talent.description}</p>
                    <div style={{display:'flex'}}><span className='card_subtitle'>Poste recherché : </span><span className='card_text'>{talent.desiredProfession}</span></div>
                    <div style={{display:'flex'}}><span className='card_subtitle'>Contrat recherché : </span><span className='card_text'>{talent.desiredContract}</span></div>
                    <br/>
                    <div style={{display:'flex'}}><span className='card_subtitle'>Experience : </span><span className='card_text'>+ 3 ans</span></div>
                    <div style={{display:'flex'}}><span className='card_subtitle'>Formation : </span><span className='card_text'>bac + 5</span></div> 
                    <div style={{display:'flex'}}><span className='card_subtitle'>Disponibilité : </span> <span className='card_text'>{talent.availability} </span></div> 
                    <div style={{display:'flex'}}><span className='card_subtitle'>Horaire : </span> <span className='card_text'>{talent.hourlyAvailability} </span></div> 
                </div>
                <div className='card_footer'> 
                    <Link to='#'><button className='card_button'>Contacter</button></Link>
                    <Link to={`/restaurant/profil/${id}`}><button className='card_button'>Voir profil</button></Link>
                </div>              
            </div>
        )
    }
    else {
        return (
            <div className ='card'>
                <div className='card_background'>
                    <img className='card_image' src={talent.src} alt='profilPicutre'/>
                </div> 
                <div className='card_info'>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <h4 className='card_title'>{talent.name}, {calculate_age(new Date(yyyy, mm, dd))}  ans </h4>
                        <div className='card_infoHeart'> 
                            <IconButton onClick={addToFavourite}>
                                <FavoriteBorderIcon className='heart_icon'/>
                            </IconButton> 
                            <span className='card_text'>({favCount})</span>
                        </div>
                    </div>  
                    <p className='card_text'>{talent.description}</p>
                    <div style={{display:'flex'}}><span className='card_subtitle'>Poste recherché : </span><span className='card_text'>{talent.desiredProfession}</span></div>
                    <div style={{display:'flex'}}><span className='card_subtitle'>Contrat recherché : </span><span className='card_text'>{talent.desiredContract}</span></div>
                    <br/>
                    <div style={{display:'flex'}}><span className='card_subtitle'>Experience : </span><span className='card_text'>+ 3 ans</span></div>
                    <div style={{display:'flex'}}><span className='card_subtitle'>Formation : </span><span className='card_text'>bac + 5</span></div> 
                    <div style={{display:'flex'}}><span className='card_subtitle'>Disponibilité : </span> <span className='card_text'>{talent.availability} </span></div> 
                    <div style={{display:'flex'}}><span className='card_subtitle'>Horaire : </span> <span className='card_text'>{talent.hourlyAvailability} </span></div> 
                </div>
                <div className='card_footer'> 
                    <Link to='#'><button className='card_button'>Contacter</button></Link>
                    <Link to={`/restaurant/profil/${id}`}><button className='card_button'>Voir profil</button></Link>
                </div>              
            </div>
        )
    }
}


function mapStateToProps(state) {
    return { userToDisplay : state.user }
}

export default connect(
    mapStateToProps, 
    null
)(TalentCard);