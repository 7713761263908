import React, { useState, useEffect } from 'react'
import './Experience'
import Experience from './Experience'
import { connect } from 'react-redux'
import { db } from '../../firebase'

function Experiences({userToDisplay}) {

    const [ experiences, setExperiences] = useState([])
    const docId = userToDisplay?.docId

    useEffect(()=>{
        let docRef = db.collection('users').doc(docId)
        docRef.get().then(function(doc) {
            if (doc.exists) {
                setExperiences(doc.data().experiences)
            }else{
                setExperiences([{firmName : '', startDate : '', endDate : '', job : ''}])
            }
        }).catch(function(error) {
        });
    },[])
   

    const addExperience = () => {
        setExperiences([...experiences, {firmName : '', startDate : '', endDate : '', job : ''}])
    }

    const handleChangeStartDate = (value, i) => {
        experiences[i].startDate = value
    }

    const handleChangeFirmName = (value, i) => {
        experiences[i].firmName = value
    }

    const handleChangeEndDate = (value, i) => {
        experiences[i].endDate = value
    }

    const handleChangeJob = (value, i) => {
        experiences[i].job = value
    }

    const handleSubmit = () => {
        db.collection('users').doc(docId).update({experiences : experiences})
    }

    const handleDelete = () => {
        setExperiences(experiences.slice(0,experiences.length-1))
    }

    return (
        <div className='experiences_container'>
            {experiences.map((experience, i)=>{
                if(i+1 === experiences.length ){
                    return <Experience index={i} 
                    experience={experience}
                    handleChangeFirmNameParent={handleChangeFirmName} handleChangeStartDateParent={handleChangeStartDate} 
                    handleChangeEndDateParent={handleChangeEndDate} handleDeleteParent={handleDelete} 
                    handleChangeJobParent={handleChangeJob}
                    key={i} isLast={true}/>
                }
                return <Experience index={i} 
                experience={experience}
                handleChangeFirmNameParent={handleChangeFirmName} handleChangeStartDateParent={handleChangeStartDate} 
                handleChangeEndDateParent={handleChangeEndDate} 
                handleChangeJobParent={handleChangeJob}
                key={i} isLast={false}/>
            })}
            <button className='experiences_buttonAdd' onClick={addExperience}>Ajouter une experience</button>
            <button className='experiences_button' onClick={handleSubmit}>Valider</button>
        </div>
    )
}

function mapStateToProps(state) {
    return { userToDisplay : state.user }
}

export default connect(
    mapStateToProps, 
    null
)(Experiences);
