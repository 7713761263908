import React, { useState } from 'react'
import fire, {db} from '../firebase'
import  { Redirect } from 'react-router-dom'
import './Signup.css'
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { IconButton } from '@material-ui/core'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError]= useState('');
    const [passwordError, setPasswordError] = useState('')
    const [userType, setUserType] = useState(null)
    const [passwordType, setPasswordType] = useState('password')
    const [iconPassword, setIconPassword]= useState(<VisibilityIcon/>)
  


    const togglePassword = () => {
        if(passwordType === 'password'){
            setPasswordType('text')
            setIconPassword(<VisibilityOffIcon/>)
        }
        else if (passwordType === 'text'){
            setPasswordType('password')
            setIconPassword(<VisibilityIcon/>)
        }
    }

    const clearErrors = () => {
        setEmailError('')
        setPasswordError('')
    }

    const handleLogin = () => {
        clearErrors();
        fire.auth().signInWithEmailAndPassword(email, password)
        .then(cred=>{
            console.log(cred.user.uid)
            return  db.collection('users').where("id", "==", cred.user.uid).onSnapshot(snapshot=>(
                setUserType(snapshot.docs.map(doc=>doc.data().type)
            )))
        })
        .catch(err=>{
            switch(err.code){
                case "auth/invalid-email":
                case 'auth/user-disabled':
                case 'auth/user-not-found':
                    setEmailError("L'email est invalide ou l'utilisateur n'existe pas")
                    break;
                case "auth/wrong-password":
                    setPasswordError('Mot de passe incorrecte')
                    break;
            }
        })
    }

    if(userType === null){
        return (
            <div>
                <div className='signup_container'>
                    <div className='signup'>
                        <div className='signup_inputContainer'>
                            <span className='signup_title'>Connexion</span>
                            <input className='signup_input' placeholder='Email' value={email} onChange={(e)=>setEmail(e.target.value)}></input>
                            <span className='signup_error'>{emailError}</span>
                            <div className='signup_passwordInputContainer'>
                                <input type={passwordType} className='signup_input' placeholder='Mot de passe' value={password} onChange={(e)=>setPassword(e.target.value)}>
                                </input>
                                <IconButton className='signup_inputIcon' onClick={togglePassword}>
                                    {iconPassword}
                                </IconButton>
                            </div>
                            <span className='signup_error'>{passwordError}</span>
                            <button className='signup_button' onClick={handleLogin}>Se connecter</button>
                            <Link className='signup_link'><span className='signup_forgetPassword'>Mot de passe oublié ?</span></Link>
                            <div className='signup_createAccountContainer'> 
                                <span className='signup_createAccountContainerText'>ou</span>
                            </div>
                            <button className='signup_buttonCreateAccount'>Créer un compte</button>
                        </div>
                    </div>
                </div>   
            </div>
        )
    }
    else if (userType[0] === 'restaurant'){
        return <Redirect to='/restaurant'/>
    }
    else if(userType[0] === 'talent'){
        return <Redirect to='/talent'/>
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onSendFirstTime: function() { 
            dispatch( {type: 'addFirstTime', firstTime : false} ) 
        }
    }
}

export default connect(
    null, 
    mapDispatchToProps
)(Login);