import React, {useState} from 'react'
import { Link, Redirect } from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu';
import { IconButton } from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SearchIcon from '@material-ui/icons/Search';
import './RealNavbar.css'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

function RealNavbar({userToDisplay}) {

    
    const location = useLocation();
    const [sidebar, setSidebar] = useState(false)
   

    const showSidebar = () => {
        setSidebar(!sidebar)
    }

    let linkToProfil = '/restaurant/monprofil'

    

    let SidebarData = [{
        title : 'Rechercher',
        path : '/restaurant',
        icon : <SearchIcon/> ,
        cName : 'nav-text'
    }, {
        title :'Favoris',
        path :'/favoris',
        icon : <FavoriteBorderIcon/>,
        cName : 'nav-text'
    }, {
        title :'Annonces',
        path :'/annonces',
        icon : <AssignmentIndIcon/>,
        cName : 'nav-text'
    },{
        title :'Mon profil',
        path :'/restaurant/monprofil',
        icon : <AccountBoxIcon/>,
        cName : 'nav-text'
    }
    , {
        title : 'Deconnexion',
        path :'/logout',
        icon : <ExitToAppIcon/>,
        cName : 'nav-text'
    }
    ]

    if(userToDisplay?.type === 'talent'){

        linkToProfil = '/talent/monprofil'

        SidebarData = [{
           title : 'Rechercher',
           path : '/talent',
           icon : <SearchIcon/> ,
           cName : 'nav-text'
       }, {
           title :'Favoris',
           path :'/talent/favoris',
           icon : <FavoriteBorderIcon/>,
           cName : 'nav-text'
       },{
           title :'Mon profil',
           path :'/talent/monprofil',
           icon : <AccountBoxIcon/>,
           cName : 'nav-text'
       }
       , {
           title : 'Deconnexion',
           path :'/logout',
           icon : <ExitToAppIcon/>,
           cName : 'nav-text'
       }
       ]
    }
    if(location.pathname === '/home' || location.pathname === '/contact'){
        return (
            <div className='navbar'>
                <Link className='nav_link' to='/home'>
                    <span className='nav-text'>Home</span>
                </Link>
                <div style={{display:'flex', justifyContent:'space-between', width:'220px'}}>
                    <Link to='/contact' className='nav_link'>
                        <span className='nav-text'>Contact</span>
                    </Link>
                    <Link className='nav_link' to='/login'>
                        <span className='nav-textLeft'>Se connecter</span>
                    </Link>
                </div>
            </div>
        )
    }
    if(location.pathname === '/login'){
        return (
            <div className='navbar'>
                <Link className='nav_link' to='/home'>
                    <span className='nav-text'>Home</span>
                </Link>
                <Link className='nav_link' to='/signup/talent'>
                    <span className='nav-textLeft'>Créer un compte</span>
                </Link>
            </div>
        )

    }else if(location.pathname === '/signup/talent' || location.pathname === '/signup/restaurant'){
        return (
            <div className='navbar'>
                <Link className='nav_link' to='/home'>
                    <span className='nav-text'>Home</span>
                </Link>
                <Link className='nav_link' to='/login'>
                    <span className='nav-textLeft'>Se connecter</span>
                </Link>
            </div>
        )
    }else if(location.pathname === '/'){
        return <Redirect to='/home'/>
    }
    else{
        let titleToDisplay;
        if(location.pathname === '/restaurant'){
            titleToDisplay = 'Rechercher'
        }
        if(location.pathname === '/favoris'){
            titleToDisplay = 'Favoris'
        }
        if(location.pathname === '/restaurant/monprofil' || location.pathname === '/modifier/monprofil' || location.pathname === '/restaurant/questionnaire'){
            titleToDisplay = 'Mon profil'
        }
        if(location.pathname === '/annonces'){
            titleToDisplay = 'Annonces'
        }  
        if(location.pathname === '/talent'){
            titleToDisplay = 'Rechercher'
        }
        if(location.pathname === '/talent/favoris'){
            titleToDisplay = 'Favoris'
        }
        if(location.pathname == '/talent/monprofil'){
            titleToDisplay = 'Mon profil'
        }

        return (
            <>
            <div className='navbar'>
                <Link className='nav_link' to='#' className='menu-bars'>
                    <IconButton onClick={showSidebar}>
                        <MenuIcon/>
                    </IconButton>
                </Link>
                <h3 className='nav_title'>{titleToDisplay}</h3>
                <Link className='nav_link' to={linkToProfil}>
                    <IconButton>
                        <AccountBoxIcon className='test'/>
                    </IconButton>
                </Link>
            </div>
            <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                <ul className='nav-menu-items'>
                    <li className='navbar-toggle'>
                        <Link className='nav_link' to='#' className='menu-bars'>
                            <IconButton onClick={showSidebar}>
                                <MenuIcon/>
                            </IconButton>
                        </Link>
                    </li>
                    {SidebarData.map((item,index)=>{
                        return(
                            <li key={index} className={item.cName}>
                                <Link className='nav_link' onClick={showSidebar} to={item.path}>
                                    {item.icon}
                                    <span className='nav_itemTitle'>{item.title}</span>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </nav>
            </>
        )
    }
}


function mapStateToProps(state) {
    return { userToDisplay: state.user };
}
  
export default connect(mapStateToProps, null)(RealNavbar);

