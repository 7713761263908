import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { db } from '../firebase'
import { connect } from 'react-redux'

import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import LaunchIcon from '@material-ui/icons/Launch';
import { IconButton } from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';


function RestaurantProfilView({userToDisplay}) {

    const [restaurant, setRestaurant] = useState()
    const [restaurantId, setRestaurantId] = useState()
    const [restaurantPicture, setRestaurantPicture] = useState([])

    const [isModalVisible, setIsModalVisible] = useState()
    const [modalSrc, setModalSrc] = useState('')
    const [annonces ,setAnnonces] = useState([])

    const { profilId } = useParams();

    const coverPicture = restaurantPicture[0]?.url
 
    useEffect(()=>{

        db.collection("users").doc(profilId)
        .get().then(function(doc) {
            if (doc.exists) {
                setRestaurant({data : doc.data(), id : doc.id});
            } else {
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
     
        db.collection('users').doc(profilId).collection('images').onSnapshot((snapshot)=>(
            setRestaurantPicture(snapshot.docs.map(doc=>doc.data()))
        ))

        console.log(restaurant?.data.id)


    },[])

    const openModal = (src) => {
        setIsModalVisible(true)
        setModalSrc(src)
    }
    const closeModal = (e) => {
        if(e.target.className === 'modal_container' )
        setIsModalVisible(false)
    }

    const displayNextPic = () => {
        let currentUrl = restaurantPicture.filter(item => item.url === modalSrc).map(item => item.url)
        let restaurantPictureTabOnly = [];
        for (let i=0; i<restaurantPicture.length;i++){
            restaurantPictureTabOnly.push(restaurantPicture[i].url)
        }
        
        let currentId = restaurantPictureTabOnly.indexOf(currentUrl[0])
        let nextPic ;
        if(currentId === (restaurantPicture.length)-1){
            nextPic = restaurantPicture[0]
        }
        else {
            nextPic = restaurantPicture[currentId+1] 
        }
        setModalSrc(nextPic.url)
    }

    const displayPreviousPic = () => {
        let currentUrl = restaurantPicture.filter(item => item.url === modalSrc).map(item => item.url)
        let restaurantPictureTabOnly = [];
        for (let i=0; i<restaurantPicture.length;i++){
            restaurantPictureTabOnly.push(restaurantPicture[i].url)
        }
        
        let currentId = restaurantPictureTabOnly.indexOf(currentUrl[0])
        let previousPic;
            if(currentId === 0 ){
                previousPic = restaurantPicture[(restaurantPicture.length)-1]
            }
            else{
                previousPic = restaurantPicture[currentId-1]
            }
            setModalSrc(previousPic.url)
    }

    if(!userToDisplay){
        return <Redirect to='/talent'/>
    }
    
    return (
        <div>
            <div className='profil'>
                <header className='profilCover'  style={{
                        backgroundSize:'cover',
                        backgroundImage:`url(${coverPicture})`,
                        backgroundPosition : 'center center'
                    }}>
                    <div className='profilCover_contents'>
                        <div className='profilCover_description'> 
                            <h1 className='profilCover_title'>
                                {restaurant?.data.name}
                            </h1>
                            <div className='profilInfo_items'>  
                                <LocationOnIcon/> 
                                <span className='profilInfo_item'>{restaurant?.data.adress}</span>
                            </div>
                            <div className='profilInfo_items'>  
                                <PhoneIcon/>
                                <span className='profilInfo_item'>{restaurant?.data.phoneNumber}</span>
                            </div>
                            <div className='profilInfo_items'>
                                <IconButton onClick={()=> window.open(`${restaurant?.data.websiteLink}`, "_blank")}>
                                    <LaunchIcon/>
                                </IconButton>
                                <span className='profilInfo_item'>Website</span>
                            </div>
                            <div className='profilInfo_items'>
                                <IconButton onClick={()=> window.open(`${restaurant?.data.instagramLink}`, "_blank")}>
                                    <InstagramIcon/>
                                </IconButton>
                                <span className='profilInfo_item'>Instagram</span>
                            </div>
                            <div className='profilInfo_items'>
                                <IconButton onClick={()=> window.open(`${restaurant?.data.facebookLink}`, "_blank")}>
                                    <FacebookIcon/>
                                </IconButton>
                                <span className='profilInfo_item'>Facebook</span>
                            </div>
                        </div>
                    </div>
                    <div className='profilCover_fadebottom'/>
                </header>
                <div className='profilContent'>
                    <h2 className='profilContent_title'>Découvrez {restaurant?.data.name}</h2>
                    <h4 className='profilContent_description'>{restaurant?.data.description} </h4>
                    <h2 className='profilContent_title'>Galerie photo</h2>
                    <div className='profilRow'>
                        <div className='profilRow_posters'>
                            {restaurantPicture.map((picture,i) => (
                                <img 
                                onClick={()=> openModal(picture.url)}
                                key={i}
                                className='profilRow_poster'
                                src={picture.url} 
                                alt={picture.name}/>
                            ))}
                        </div>
                    </div>
                    {/* <h2 className='profilContent_title'>Toutes les annonces</h2>
                            <Grid container spacing={3} style={{display:'flex', justifyContent:'center'}}> 
                                {annonces.map((annonce,i )=>(
                                    <AnnonceCardView key={i} annonceInfo={annonce.data} />
                                ))}
                            </Grid> */}
                </div>
                <div onClick={(e)=>closeModal(e)} className={`profilModal ${isModalVisible && 'profilModal_show'}`}>
                    <div onClick={(e)=>closeModal(e)} className='modal_container'>
                        <img className='profilModal_content' src={modalSrc} alt='1'></img>
                        <ArrowForwardIosIcon onClick={()=>displayNextPic()} className='profilModal_nextButton'/>
                        <ArrowBackIosIcon onClick={()=>displayPreviousPic()} className='profilModal_previousButton'/>
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return { userToDisplay : state.user }
}

export default connect(
    mapStateToProps, 
    null
)(RestaurantProfilView);

