import React, { useState } from 'react'
import './Experiences.css'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { IconButton } from '@material-ui/core'

function Experience(props) {
    let experience = props.experience
    console.log(experience)

    const [firmName, setFirmName] = useState(experience?.firmName)
    const [job, setJob] = useState(experience?.job)
    const [startDate, setStartDate,] = useState(experience?.startDate)
    const [endDate, setEndDate] = useState(experience?.endDate)

    const handleChangeFirmName = (e) => {
        setFirmName(e.target.value)
        props.handleChangeFirmNameParent(e.target.value, props.index)
    }

    const handleChangeStartDate = (e) => {
        setStartDate(e.target.value)
        props.handleChangeStartDateParent(e.target.value, props.index)
    }

    const handleChangeEndDate = (e) => {
        setEndDate(e.target.value)
        props.handleChangeEndDateParent(e.target.value, props.index)
    }

    const handleChangeJob = (e) => {
        setJob(e.target.value)
        props.handleChangeJobParent(e.target.value, props.index)
    }
    const handleDelete = () => {
        props.handleDeleteParent()
    }
    if(props.isLast){
        return (
            <div className='experience_container'>
                <IconButton onClick={handleDelete} className='experience_deleteIcon'><DeleteForeverIcon/></IconButton>
                <span className='experience_title'>Experience n°{props.index + 1}</span>
                <div className='experience_inputContainer'>
                    <div style={{paddingTop:'20px'}}>
                        <input className='experience_input' value={firmName} onChange={(e)=>handleChangeFirmName(e)} placeholder="Nom de l'établissement"></input>
                    </div>
                    <div style={{paddingTop:'20px'}}>
                        <select onChange={(e)=>handleChangeJob(e)} defaultValue='Poste occupé'  placeholder='Poste recherché' className='personnalInfo_select'>
                            <option value='Poste occupé' disabled>Poste occupé</option>
                            <optgroup  label='En cuisine'>
                                <option value='Chef'>Chef</option>
                                <option value='Chef de partie'>Chef de partie</option>
                                <option value='Commis'>Commis</option>
                                <option value='Plongeur'>Plongeur</option>
                            </optgroup>
                            <optgroup label='En salle'>
                                <option value='Directeur'>Directeur</option>
                                <option value='Manager'>Manager</option>
                                <option value='Chef de rang'>Chef de rang</option>
                                <option value='Runner'>Runner</option>
                                <option value='Barman'>Barman</option>
                            </optgroup>
                        </select>
                    </div>
                    <div style={{paddingTop:'20px'}}>
                        <input type='date' className='experience_input' value={startDate} onChange={(e)=>handleChangeStartDate(e)} placeholder="Date de début"></input>
                    </div>
                    <div style={{paddingTop:'20px'}}>
                        <input type='date' className='experience_input' value={endDate} onChange={(e)=>handleChangeEndDate(e)} placeholder="Date de fin"></input>
                    </div>
                </div>    
            </div>
        )
    }
    return (
        <div className='experience_container'>
            <span className='experience_title'>Experience n°{props.index + 1}</span>
            <div className='experience_inputContainer'>
                <div style={{paddingTop:'20px'}}>
                    <input className='experience_input' value={firmName} onChange={(e)=>handleChangeFirmName(e)} placeholder="Nom de l'établissmeent"></input>
                </div>
                <div style={{paddingTop:'20px'}}>
                        <select defaultValue='Poste recherché'  placeholder='Poste recherché' className='personnalInfo_select'>
                            <option value='Poste recherché' disabled>Poste recherché</option>
                            <optgroup  label='En cuisine'>
                                <option value='Chef'>Chef</option>
                                <option value='Chef de partie'>Chef de partie</option>
                                <option value='Commis'>Commis</option>
                                <option value='Plongeur'>Plongeur</option>
                            </optgroup>
                            <optgroup label='En salle'>
                                <option value='Directeur'>Directeur</option>
                                <option value='Manager'>Manager</option>
                                <option value='Chef de rang'>Chef de rang</option>
                                <option value='Runner'>Runner</option>
                                <option value='Barman'>Barman</option>
                            </optgroup>
                        </select>
                    </div>
                <div style={{paddingTop:'20px'}}>
                    <input type='date' className='experience_input' value={startDate} onChange={(e)=>handleChangeStartDate(e)} placeholder="Date de début"></input>
                </div>
                <div style={{paddingTop:'20px'}}>
                    <input type='date' className='experience_input' value={endDate} onChange={(e)=>handleChangeEndDate(e)} placeholder="Date de fin"></input>
                </div>
            </div>    
        </div>
    )
}

export default Experience
