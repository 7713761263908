import React, { useState, useEffect } from 'react'
import './Apply.css'
import { useParams, Redirect } from 'react-router-dom'
import { db } from '../firebase'
import { connect } from 'react-redux'
import emailjs from 'emailjs-com'

function Apply({userToDisplay}) {

    const { restaurantId } = useParams();
    const [restaurantEmail, setRestaurantEmail] = useState()
    const docId = userToDisplay?.docId
    const [talentEmail, setTalentEmail] = useState()

    useEffect(()=>{
        db.collection("users").doc(restaurantId)
        .get().then(function(doc) {
            if (doc.exists) {
                setRestaurantEmail(doc.data().email);
            } else {
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });

        db.collection("users").doc(docId)
        .get().then(function(doc) {
            if (doc.exists) {
                setTalentEmail(doc.data().email);
            } else {
                console.log("No such document!");
            }
        }).catch(function(error) {
            console.log("Error getting document:", error);
        });
    },[])

    const sendEmail = (e) => {
        e.preventDefault();
    
        // emailjs.sendForm('service_5kiqkeo', "template_sv77vqa", e.target, "user_VKLBaSnkgJ0xwHfKO49BT")
        //   .then((result) => {
        //       console.log(result.text);
        //   }, (error) => {
        //       console.log(error.text);
        //   });
      }

    console.log(restaurantEmail)
    console.log(talentEmail)

    if(!userToDisplay){
        return <Redirect to='/talent'/>
    }

    return (
        <div style={{paddingTop:'100px'}}>
            <form className="contact-form" onSubmit={sendEmail}>
                
                <input type="hidden" name="contact_number" />
                <input  value={restaurantEmail} type="hidden" name="to_email" />
                <input  value={talentEmail} type='hidden' name="talent_email" />
                <textarea placeholder='Votre message' className='contactus_input' name="message" />
                <input className='contactus_button' type="submit" value="Send" />
            </form>
        </div>
    )
}

function mapStateToProps(state) {
    return { userToDisplay : state.user }
}

export default connect(
    mapStateToProps, 
    null
)(Apply);



