import React, {useState, useEffect} from 'react'
import './SearchTalent.css'
import Grid from '@material-ui/core/Grid';
import TalentCard from '../TalentCard/TalentCard';
import { Link } from 'react-router-dom'
import { db } from '../firebase'
import { connect } from 'react-redux'

function SearchTalent(props) {

    const [jobChoosen, setJobChoosen] = useState()
    const [contractChoosen, setContractChoosen] = useState()
    const [experienceChoosen, setExperienceChoosen] = useState()
    const [availabilityChoosen, setAvailabilityChoosen] = useState()
    const [disconnect, setDisconnect]=useState(false)
   // const [talentToDisplay, setTalentToDisplay] = useState([])
    const [talents, setTalents] = useState([]);
    const [favourites, setFavourites] = useState([])
    const isModalVisible = false;
    const docId = props.userToDisplay?.docId

    

    
    const handleSearch = () => {
    //     console.log(experienceChoosen, availabilityChoosen)

    //     let filterArray = [jobChoosen, contractChoosen]
    //     let tempTalentToDisplay = []

    //     for (let i=0; i<talent.length;i++){
    //         console.log(talent[i].desiredProfession)
    //         if(filterArray.includes(talent[i].desiredProfession && talent[i].desiredContract)){
    //             tempTalentToDisplay.push(talent[i])
    //         }
    //     }
    //    setTalentToDisplay(tempTalentToDisplay)
    }

    useEffect(()=>{
        if(docId){ 
            db.collection('users').where('type', '==', 'talent').onSnapshot(snapshot=>(
                setTalents(snapshot.docs.map(doc=>({
                id: doc.id, 
                data :doc.data()
            })))  
            ))
            
            let favouriteRef = db.collection('users').doc(docId).collection('favoris')
            favouriteRef.onSnapshot((snapshot)=>(
                setFavourites(snapshot.docs.map(doc=>({
                   data : doc.data(),
                   id : doc.id
                })))
            ))
            
        }
    },[docId])

    let ids = [];
        
    for(let i = 0 ; i<favourites.length;i++){
    ids.push(favourites[i].data.id)
    }
    

    let talentsData = [];
    for(let i = 0; i<talents.length;i++){
    talentsData.push(talents[i].data)
    }


    for(let j = 0; j<ids.length;j++){
        for(let i = 0;i<talentsData.length;i++){

            if(talentsData[i].id === ids[j]){
                talentsData[i].favourite = true
                talentsData[i].favouriteDocId = favourites[j].id
            }
            if(!talentsData[i].favourite){
                talentsData[i].favourite = false
            }
        }
    }

    let talentToDisplay = talentsData.map(function(talent,i){
        if(talent.favourite === true){
            return (
                <TalentCard key={i} id={talent.id} fav={true} talent={talent}/>
            )
        }else{
            return (
                <TalentCard key={i} id={talent.id} fav={false} talent={talent}/>
            )
        }
    })

    return (
        <div className='searchTalent'>
            <div className='searchContainer'>
                <div className='search_filter'>
                        <select defaultValue='Poste recherché' onChange={(e)=>setJobChoosen(e.target.value)} className='search_filterInput'>
                            <option value="Poste recherché" disabled>Poste recherché</option>
                            <optgroup label="En cuisine">
                                <option value="Chef">Chef</option>
                                <option value="Chef de partie">Chef de partie</option>
                                <option value="Commis">Commis</option>
                                <option value="Plongeur">Plongeur</option>
                            </optgroup>
                            <optgroup label="En salle">
                                <option value="Directeur">Directeur</option>
                                <option value="Manager">Manager</option>
                                <option value="Chef de rang">Chef de rang</option>
                                <option value="Runner">Runner</option>
                                <option value="Barman">Barman</option>
                            </optgroup>
                        </select>
                        <select defaultValue='Type de contrat' onChange={(e)=>setContractChoosen(e.target.value)} placeholder='Type de contrat' className='search_filterInput'>
                            <option value="Type de contrat" disabled>Type de contrat</option>
                            <option value="CDI">CDI</option>
                            <option value="CDD">CDD</option>
                            <option value="Extra">Extra</option>
                            <option value="Saisonnier">Saisonnier</option>
                        </select>
                        <select defaultValue='Experience requise' onChange={(e)=>setExperienceChoosen(e.target.value)} className='search_filterInput'>
                            <option value="Experience requise" disabled >Experience requise</option>
                            <option value="Aucune">Aucune</option>
                            <option value="+1 ans">+1 ans</option>
                            <option value="+2 ans">+2 ans</option>
                            <option value="+3 ans">+3 ans</option>
                        </select>
                        <select defaultValue='Disponibilité' onChange={(e)=>setAvailabilityChoosen(e.target.value)} placeholder='Experience requise' className='search_filterInput'>
                            <option value="Disponibilité" disabled>Disponibilité</option>
                            <option value="Immédiate">Immédiate</option>
                            <option value="Sous 1 mois">Sous 1 mois</option>
                            <option value="Sous 2 mois">Sous 2 mois</option>
                        </select>
                </div>
                <button onClick={handleSearch}  className='search_button'>Lancer la recherche</button>
                <div className={`annonceModal ${isModalVisible && 'annonceModal_show'}`}>
                <div className='modal_container'>
                    <div className='annonceModal_content'>
                        <h1>Bienvenue sur on a tray</h1>
                        <h2>Nous sommes très heureux de vous avoir parmis nos utilisateurs</h2>
                        <p>Pour bien commencer votre experience, nous vous conseillons de mettre à jour votre profil</p>
                        <Link to='/restaurant/monprofil'><button>Voir mon profil</button></Link> 
                    </div>
                </div>
            </div>
            </div>
            <Grid container spacing={3} style={{display:'flex', justifyContent:'center'}}> 
                {talentToDisplay}    
            </Grid>
        </div>
    )
}



function mapStateToProps(state) {
    return { userToDisplay : state.user }
}

export default connect(
    mapStateToProps, 
    null
)(SearchTalent);