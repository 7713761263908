import React, { useState, useEffect} from 'react'
import './TalentProfil.css'
import PersonnalInfo from './PersonnalInfo'
import Experiences from './Experiences/Experiences'
import Formations from './Formations/Formations'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

function ModifyTalentProfil({userToDisplay}) {

    const [isPersonnalInfoOpen, setIsPersonnalInfoOpen] = useState(true)
    const [isExperiencesInfoOpen, setIsExperiencesInfoOpen] = useState(false)
    const [isFormationsInfoOpen, setIsFormationsInfoOpen] = useState(false)

    console.log(userToDisplay)

    const openPersonalInfo = () => {
        
            setIsExperiencesInfoOpen(false)
        
        
            setIsFormationsInfoOpen(false)
        
        setIsPersonnalInfoOpen(true)
    }

    const openExperiencesInfo = () => {
       
            setIsPersonnalInfoOpen(false)
        
  
            setIsFormationsInfoOpen(false)
        
        setIsExperiencesInfoOpen(true)
    }
    
    const openFormationsInfo = () => {
 
            setIsExperiencesInfoOpen(false)
        

            setIsPersonnalInfoOpen(false)
    
        setIsFormationsInfoOpen(true)
    }

    let childrenToDisplay = <PersonnalInfo/>

    if(isExperiencesInfoOpen){
        childrenToDisplay= <Experiences/>
    }else if(isFormationsInfoOpen){
        childrenToDisplay= <Formations/>
    }


    if(!userToDisplay){
        return <Redirect to='/talent'/>
    }
    return (
        <div className='talentProfil'>
            <div className='searchRestaurantContainer_buttons'>
                <div className={`search_item ${isPersonnalInfoOpen && 'search_itemActive'}`}>
                    <p onClick={openPersonalInfo}>Mes informations personnel</p>
                </div>
                <div className={`search_item ${isExperiencesInfoOpen && 'search_itemActive'}`}>
                    <p onClick={openExperiencesInfo}>Mes experiences</p>
                </div>
                <div className={`search_item ${isFormationsInfoOpen && 'search_itemActive'}`}>
                    <p onClick={openFormationsInfo}>Mes formations</p>
                </div>
            </div>
            {childrenToDisplay}
        </div>
    )
}
function mapStateToProps(state) {
    return { userToDisplay : state.user }
}

export default connect(
    mapStateToProps, 
    null
)(ModifyTalentProfil);
