import React, { useState, useEffect } from 'react'
import './RestaurantWishlist.css'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { db } from '../firebase'
import { Grid } from '@material-ui/core'
import TalentCard from '../TalentCard/TalentCard'

function RestaurantWishlist({userToDisplay}) {

    const docId = userToDisplay?.docId
    const [favourites, setFavourites] = useState([])
    
    useEffect(()=>{
        let favouriteRef = db.collection('users').doc(docId).collection('favoris')
        favouriteRef.onSnapshot((snapshot)=>(
            setFavourites(snapshot.docs.map(doc=>({
               data : doc.data(),
               id : doc.id
            })))
        ))
    },[])
    
    console.log(favourites)
    if(!userToDisplay){
        return <Redirect to='/restaurant'/>
    }else if(favourites.length > 0){
        return (
            <div style={{paddingTop:'150px'}}>
               <Grid container spacing={3} style={{display:'flex', justifyContent:'center'}}> 
                
                {favourites.map((talent,i )=>(
                    // ça ne va pas, faire que l'id du doc partent sous la même forme que dans search talent!
                        <TalentCard key={i} fav={true} favouriteDocId={talent.id}  talent={talent.data}/>
                ))}
                </Grid>   
            </div>
        )
    }else if (favourites.length === 0){
        return (
            <div style={{paddingTop:'200px', display:'flex', justifyContent:'center'}}>
            <h1 className='restaurantWhishlist_title'>Vous n'avez pas ajouté de talent dans vos favoris...</h1>
            </div>
        )
    }
    
}

function mapStateToProps(state) {
    return { userToDisplay : state.user }
  }
    
  export default connect(
    mapStateToProps, 
    null
  )(RestaurantWishlist);